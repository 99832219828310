import { useQuery } from "@tanstack/react-query";
import { IArea, IBird, ICreatePlace, IPlace, createPlace, getAllAreas, getAllBirds, getAllPlaces } from "../../../api/exploreApi";
import { useForm } from "react-hook-form";
import { ChangeEvent, useEffect, useRef, useState } from "react";
import { theme } from "../../../theme-colors/theme";
import { Editor } from "@toast-ui/react-editor";
import { Box } from "@mui/material";
import { Theme, useTheme } from '@mui/material/styles';
import OutlinedInput from '@mui/material/OutlinedInput';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import BirdListForPlace, { IDataGrid } from "./BirdListForPlace";
import PlaceList from "./PlaceList";
import { GridRowParams, GridRowSelectionModel } from "@mui/x-data-grid";

export default function PlaceUploader() {

    const { isLoading: placeLoading, data: fetchPlaceData, refetch: refetchPlace } = useQuery<IPlace[]>({
        queryKey: ["placesAll"],
        queryFn: () => getAllPlaces(),
    });
    const { isLoading: areaLoading, data: areaData } = useQuery<IArea[]>({
        queryKey: ["area"],
        queryFn: () => getAllAreas(),
    });

    interface ImagePreview {
        file: File;
        url: string;
    }
    const {
        register, // inpur 값을 훅에 등록해줍니다.
        handleSubmit, // onSubmit 실행 전에 검증해줍니다.
        watch, // form의 입력값들의 변화를 관찰할 수 있게 해줍니다.
        reset,
        formState: { isSubmitSuccessful },
    } = useForm<ICreatePlace>({
    });
    const onSubmit = (data: ICreatePlace) => {

        console.log(data);
        data.file = selectedImage;
        data.around = editorRefAround.current?.getInstance().getMarkdown() || "";
        data.around = data.around.replaceAll("\n", "  \n");

        data.visitTime = editorRefWhen.current?.getInstance().getMarkdown() || "";
        data.visitTime = data.visitTime.replaceAll("\n", "  \n");

        data.tips = editorRefTip.current?.getInstance().getMarkdown() || "";
        data.tips = data.tips.replaceAll("\n", "  \n");

        const fetchNewPlaces = async () => {
            const newPlaceData = await createPlace(
                data
            );
            refetchPlace();
        };
        fetchNewPlaces();
    };

    useEffect(() => {
        reset();
    }, [isSubmitSuccessful])


    // 이미지 업로드와 삭제 
    const [selectedImage, setSelectedImage] = useState<File>();
    const [previewUrls, setPreviewUrls] = useState<ImagePreview[]>([]);
    const fileInputRef = useRef<HTMLInputElement>(null);

    const handleImageChange = (event: ChangeEvent<HTMLInputElement>) => {
        if (event.target.files && event.target.files.length > 0) {

            const file = event.target.files[0];
            setSelectedImage(file);
            const newPreviewUrls = {
                file,
                url: URL.createObjectURL(file)
            };
            setPreviewUrls([newPreviewUrls]);
        }
    }

    // 컨텐츠 글 입력
    const editorRefWhen = useRef<Editor>(null);
    const editorRefAround = useRef<Editor>(null);
    const editorRefTip = useRef<Editor>(null);

    return (
        <>  <div>
            <form
                style={{ display: "flex", flexDirection: "column", width: "500px", gap: "10px" }}
                onSubmit={handleSubmit(onSubmit)}
            >
                <text>장소 이름</text>
                <input {...register("name", { required: "장소 이름 필수 작성" })} placeholder="" style={{ height: "30px" }} />
                <text>지역</text>
                <Select
                    {...register("areaId", { required: true })}
                    sx={{ height: "40px" }}
                >
                    {areaData?.map((area) => (
                        <MenuItem key={area.name} value={area.areaId}>
                            {area.name}
                        </MenuItem>
                    ))}
                </Select>

                <text>위도</text>
                <input {...register("lat", { required: true })} placeholder="" style={{ height: "30px" }} />
                <text>경도</text>
                <input {...register("lng", { required: true })} placeholder="" style={{ height: "30px" }} />
                <text>난이도</text>
                <Select
                    {...register("level", { required: true })}
                    sx={{ height: "40px" }}
                >
                    <MenuItem key="EASY" value="EASY"> EASY</MenuItem>
                    <MenuItem key="MEDIUM" value="MEDIUM"> MEDIUM</MenuItem>
                    <MenuItem key="HARD" value="HARD"> HARD</MenuItem>

                </Select>
                <text>관람 소요 시간 (hour 단위)</text>
                <input {...register("hours", { required: true })} style={{ height: "30px" }} type="number" />

                <text>When to visit (방문 시기)</text>
                <Box sx={{ backgroundColor: "white" }}>
                    <Editor
                        initialValue=" "
                        previewStyle="vertical"
                        height="200px"
                        initialEditType="wysiwyg"
                        useCommandShortcut={true}
                        toolbarItems={[
                            ["heading"],
                            ["scrollSync"],
                        ]}
                        theme="dark"
                        usageStatistics={false}
                        ref={editorRefWhen}
                    />
                </Box>

                <text>Getting around (구경 거리)</text>
                <Box sx={{ backgroundColor: "white" }}>
                    <Editor
                        initialValue=" "
                        previewStyle="vertical"
                        height="200px"
                        initialEditType="wysiwyg"
                        useCommandShortcut={true}
                        toolbarItems={[
                            ["heading"],
                            ["scrollSync"],
                        ]}
                        theme="dark"
                        usageStatistics={false}
                        ref={editorRefAround}
                    />
                </Box>

                <text>Traveler tips (팁 정보)</text>
                <Box sx={{ backgroundColor: "white" }}>
                    <Editor
                        initialValue=" "
                        previewStyle="vertical"
                        height="200px"
                        initialEditType="wysiwyg"
                        useCommandShortcut={true}
                        toolbarItems={[
                            ["heading"],
                            ["scrollSync"],
                        ]}
                        theme="dark"
                        usageStatistics={false}
                        ref={editorRefTip}
                    />
                </Box>

                <input
                    {...register("file")}
                    type="file"
                    accept="image/*"
                    onChange={handleImageChange}
                    ref={fileInputRef}
                />
                <div style={{ display: 'flex', flexWrap: 'wrap' }}>
                    {previewUrls.map((preview, index) => (
                        <div key={index} style={{ position: 'relative', marginRight: '10px', marginBottom: '10px' }}>
                            <img
                                src={preview.url}
                                alt={`Preview ${index + 1}`}
                                style={{ width: '100px', height: '100px' }}
                            />
                        </div>
                    ))}
                </div>

                <button
                    style={{
                        backgroundColor: theme.btnColor,
                        borderRadius: "10px",
                        color: theme.textColor,
                        height: "30px"
                    }}>추가
                </button>
            </form>
        </div>
            <div style={{ display: "flex", justifyContent: "center" }}>
                <PlaceList places={fetchPlaceData} />
            </div>
        </>)
}