import * as React from 'react';
import Table from '@mui/joy/Table';
import { IBird, deleteBird, getBirdById } from '../../../api/exploreApi';
import { DataGrid, GridColDef, GridRowSelectionModel } from '@mui/x-data-grid';
import { TextField, Box, Container, Button } from '@mui/material';
import { useEffect, useState } from 'react';
import { useQuery } from '@tanstack/react-query';
import { useNavigate } from 'react-router-dom';

interface BirdsListProps {
  birds: IBird[] | undefined;
}

interface IDataGrid {
  id: number
  birdId: string
  name: string
}

export default function BirdList(props: BirdsListProps) {
  const columns: GridColDef<IDataGrid>[] = [
    { field: 'name', headerName: 'name', width: 300, },
    { field: 'birdId', headerName: 'birdId', width: 500 },
  ];


  const [searchText, setSearchText] = useState<string>("");
  const [pageSize, setPageSize] = useState<number>(5);
  const [selectedRows, setSelectedRows] = useState<GridRowSelectionModel>([]);
  const [rows, setRows] = useState<IDataGrid[]>([]);

  const navigate = useNavigate();


  const handleSearch = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSearchText(event.target.value);
  };

  const handleDelete = async () => {
    if (window.confirm("선택한 항목을 삭제합니다.")) {
      if (selectedRows.length > 0) {
        const selectedIds = selectedRows as number[];
        const birdsToDelete = rows.filter(row => selectedIds.includes(row.id));

        for (const bird of birdsToDelete) {
          await deleteBird(bird.birdId);
        }

        const newBirds = rows.filter(row => !selectedIds.includes(row.id));
        setRows(newBirds);
        setSelectedRows([]);
      }
    } else {
    }
  };

  const handleDetail = async () => {
    if (selectedRows.length > 0) {
      const selectedIds = selectedRows as number[];
      const birdsToDetail = rows.filter(row => selectedIds.includes(row.id));
      window.open(`/explore/birds/${birdsToDetail[0].birdId}`);
    }
  };

  useEffect(() => {
    const filteredRows = props.birds?.filter((bird) =>
      bird.name.toLowerCase().includes(searchText.toLowerCase())
    ).map((bird, index) => ({
      id: index + 1, // or another unique identifier
      birdId: bird.birdId,
      name: bird.name,
    })) || [];

    setRows(filteredRows);
  }, [searchText, props.birds]);


  return (
    <Container>
      <Box marginBottom={2}>
        <TextField
          label="Search"
          variant="outlined"
          fullWidth
          onChange={handleSearch}
          value={searchText}
        />
      </Box>
      <Box sx={{ height: 400, width: '100%' }} marginBottom={2}>
        <DataGrid
          rows={rows}
          columns={columns}
          initialState={{
            pagination: {
              paginationModel: {
                pageSize: 5,
              },
            },
          }}
          pageSizeOptions={[5]}
          checkboxSelection
          disableRowSelectionOnClick
          onRowSelectionModelChange={(newSelectionModel) => {
            setSelectedRows(newSelectionModel);
          }}
        />
      </Box>
      <Box marginBottom={2} columnGap={2}>
        <Button
          sx={{ marginRight: "5px" }}
          variant="contained"
          color="error"
          onClick={handleDelete}
          disabled={selectedRows.length === 0}
        >
          Delete
        </Button>
        <Button
          variant="contained"
          color="info"
          onClick={handleDetail}
          disabled={selectedRows.length !== 1}
        >
          Detail
        </Button>
      </Box>
    </Container>
  );
}
