import styled from "styled-components";
export const Title = styled.div`
  @media (max-width: 950px) {
    font-size: 20px;
  }
  font-size: 40px;
  font-weight: 100;
  text-align: center;
  font-family: "Cardo", serif;
  padding: 20px;
  cursor: pointer;
`;
export const Paragraph = styled.pre`
  font-family: "Cardo", serif;
  font-size: 18px;
  @media (max-width: 950px) {
    font-size: 9px;
  }
  left: 15%;
  text-align: center;
  line-height: 120%;
  letter-spacing: 0.5pt;
  word-wrap: break-word; /* 긴 단어를 줄 바꿈합니다. */
  overflow-wrap: break-word; /* 긴 단어를 줄 바꿈합니다. */
`;
