import styled from "styled-components";
import Autocomplete from "@mui/material/Autocomplete";
import TextField from "@mui/material/TextField";
import { useRecoilState, useRecoilValue, useSetRecoilState } from "recoil";
import { mapAreaAtom, mapSeasonAtom } from "../../screens/pages/explore/atom";
import { LocationIcon } from "../icons/LocationIcon";
import { theme } from "../../theme-colors/theme";
import { RowStack } from "./Container";
import { IArea, IMonth } from "../../api/exploreApi";

export const RowContainer = styled.div`
  width: 100%;
  text-align: center;
  justify-content: center;
  align-items: center;
  display: flex;
  flex: 1;
  background-color: ${(props) => props.theme.lightColor};
  flex-direction: row;
  padding: 5% 10%;
`;
const IconButton = styled.button`
  padding: 0px 15px 0px 12px;
  border: none;
  background-color: transparent;
  height: 40px;
  cursor: pointer;
  transition-duration: 0.3s;
  :hover {
    background-color: rgb(255, 230, 230);
    transition-duration: 0.3s;
  }
`;

interface ISearchBar {
  onClick: any;
}

function SearchBar({ onClick }: ISearchBar) {
  const data = useRecoilValue<IArea[]>(mapAreaAtom);
  const season = useRecoilValue<IMonth[]>(mapSeasonAtom);

  const mixedArray: any[] = [...data, ...season];
  const setMapAreaAtom = useSetRecoilState(mapAreaAtom);
  const setMapSeasonAtom = useSetRecoilState(mapSeasonAtom);

  const onSelect = (event: any, newValue: IArea[] | IMonth[]) => {
    const selectedName = new Set(newValue.map((val) => val.name));
    const newArea = data.map((item) => {
      if (selectedName.has(item.name)) {
        return { ...item, selected: true };
      } else return { ...item, selected: false };
    });
    const newSeason = season.map((item) => {
      if (selectedName.has(item.name)) {
        return { ...item, selected: true };
      } else return { ...item, selected: false };
    });
    setMapAreaAtom(newArea);
    setMapSeasonAtom(newSeason);
  };

  return (
    <RowStack marginY="30px">
      <Autocomplete
        multiple
        id="multiple-limit-tags"
        options={mixedArray}
        groupBy={(option) => option.category}
        getOptionLabel={(option) => option.name}
        value={mixedArray.filter((item) => item.selected)}
        onChange={onSelect}
        renderInput={(params) => (
          <TextField
            {...params}
            label="Search Keywords"
            placeholder="Favorites"
          />
        )}
        sx={{ width: "600px", backgroundColor: "whitesmoke" }}
      />
      <IconButton onClick={onClick}>
        <LocationIcon fill={theme.textColor} />
      </IconButton>
    </RowStack>
  );
}

export default SearchBar;
