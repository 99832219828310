/* eslint-disable */

import { Link, useNavigate, useParams } from "react-router-dom";
import {
  ColumnContainer,
  RowStack,
  ColumnStack,
} from "../../../components/common/Container";
import { Title } from "../../../components/common/Text";
import ImageCarousel from "./ImageCarousel";
import { HorizontalLine } from "../../../components/common/Shape";
import BirdInfoCard from "./BirdInfoCard";
import Box from "@mui/material/Box";
import ReactMarkdown from "react-markdown";
import remarkGfm from "remark-gfm";
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";
import { theme } from "../../../theme-colors/theme";
import PlaceCard from "../placepost/PlaceCard";
import { useQuery } from "@tanstack/react-query";
import Button from "@mui/material/Button";
import {
  IBird,
  IMonth,
  IPlace,
  getBirdById,
  getAllPlaces,
  getImageById,
  getMonthsByBirdId,
  getPlacesByBirdId,
} from "../../../api/exploreApi";
import { readImageFromS3 } from "../../../functions/readImageFromS3";

export function BirdPost() {
  const { birdId } = useParams<string>();
  const { isLoading: placeLoading, data: placeData } = useQuery<IPlace[]>({
    queryKey: ["place"],
    queryFn: () => getPlacesByBirdId(birdId),
  });
  const { isLoading: monthLoading, data: monthData } = useQuery<IMonth[]>({
    queryKey: ["month"],
    queryFn: () => getMonthsByBirdId(birdId),
  });
  const { isLoading: birdLoading, data: birdData } = useQuery<IBird>({
    queryKey: ["bird"],
    queryFn: () => getBirdById(birdId),
  });
  const { isLoading: imgLoading, data: images } = useQuery<string[]>({
    queryKey: ["bird_images"],
    queryFn: () => getImageById(birdId),
  });


  const navigate = useNavigate();

  return (
    <ColumnContainer>
      <ColumnStack>
        <Title>{birdData?.name}</Title>
        <HorizontalLine />
        <ImageCarousel
          images={
            images?.map((img) => readImageFromS3(img))
          }
        ></ImageCarousel>
        <Box sx={{ flexGrow: 1, padding: "50px", width:"100%" }}>
          <Grid container spacing={2}>
            <Grid item lg={4} xs={4}>
              {placeLoading ? null : (
                <BirdInfoCard
                  place={placeData?.map((place) => place.name).join(",")}
                  season={monthData?.map((month) => month.name).join(",")}
                />
              )}
            </Grid>
            <Grid item lg={8} xs={8}>
              <Typography
                sx={{
                  wordWrap: "break-word",
                  fontSize: "19px",
                  fontFamily: "Cardo",
                  maxWidth: "800px",
                }}
              >
                <ReactMarkdown remarkPlugins={[remarkGfm]}>
                  {birdData?.content}
                </ReactMarkdown>
              </Typography>
            </Grid>
          </Grid>
        </Box>
      </ColumnStack>
      <ColumnStack
        padding="40px 0"
        minHeight="500px"
        sx={{
          backgroundColor: theme.blueColor,
        }}
      >
        <Typography
          sx={{
            wordWrap: "break-word",
            fontSize: "19px",
            fontFamily: "Cardo",
            padding: "0 100px",
          }}
        >
          <ReactMarkdown 
          remarkPlugins={[remarkGfm]}
          >
            {birdData?.content}
          </ReactMarkdown>
        </Typography>
      </ColumnStack>

      <ColumnStack padding="40px 0" minHeight="500px">
        <Title>Places</Title>
        <HorizontalLine />
        {placeLoading ? null : (
          <Box
            sx={{
              display: "flex",
              gap: 1,
              py: 1,
              overflow: "auto",
              width: "90%",
              scrollSnapType: "x mandatory",
              "& > *": {
                scrollSnapAlign: "center",
              },
              "::-webkit-scrollbar": { display: "none" },
            }}
          >
            {placeData?.map((place: IPlace) => (
              <PlaceCard data={place}></PlaceCard>
            ))}
          </Box>
        )}
      </ColumnStack>

      <ColumnStack
        padding="40px 0"
        minHeight="300px"
        gap="20px"
        sx={{
          backgroundColor: theme.btnColor,
        }}
      >
        <Typography
          sx={{
            fontSize: "25px",
            fontFamily: "Cardo",
          }}
        >
          Do you want to join the Spark Bird Tour's tour?
        </Typography>
        <Button
          style={{
            backgroundColor: theme.textColor,
            fontFamily: "Cardo",
          }}
          variant="contained"
          onClick={() => {
            navigate(`/tour`);
          }}
        >
          Yes!
        </Button>
      </ColumnStack>
    </ColumnContainer>
  );
}
export default BirdPost;
