import { Outlet, useLocation, useNavigate } from "react-router-dom";

import getPathHeader from "../../functions/getPathHeader";

import * as React from "react";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import Container from "@mui/material/Container";
import Button from "@mui/material/Button";

import { theme } from "../../theme-colors/theme";
import NavigationMenu from "../../enum/NavigationMenu";
import Header from "./Header";
export function NavigationBar() {
  const navigate = useNavigate();
  const onMainClick = () => {
    navigate("/");
  };

  const location = useLocation();
  const pathHeader = getPathHeader(location.pathname);
  return (
    <AppBar
      position="static"
      sx={{
        backgroundColor: theme.textColor,
      }}
    >
      <Container maxWidth="xl">
        <Toolbar sx={{ justifyContent: "space-between" }}>
          <Box
            sx={{
              display: { xs: "none", sm: "flex" },
            }}
          >
            <Box
              sx={{
                display: {
                  xs: "none",
                  md: "flex",
                },
                width: "30px",
                height: "30px",
                marginRight: "5px"
              }}
            >
              <img src="./store/logo.png" />
            </Box>

            <Typography
              color={"white"}
              variant="h6"
              component="a"
              href=""
              sx={{
                mr: 2,
                display: { xs: "none", md: "flex" },
                fontFamily: "inherit",
                letterSpacing: ".1rem",
              }}
              onClick={onMainClick}
            >
              Spark Bird Tour
            </Typography>
          </Box>
          <Box
            sx={{
              display: { xs: "none", sm: "block" },
            }}
          >
            {NavigationMenu.map((item) => (
              <Button
                key={item}
                sx={{ color: "#fff" }}
                onClick={() => {
                  navigate(`/${item}`);
                }}
              >
                {item}
              </Button>
            ))}
          </Box>
          <Box
            sx={{
              display: { xs: "none", sm: "block" },
            }}
          >
            <Button
              sx={{
                display: { xs: "none", sm: "block" },
                minWidth: "200px",
                color: theme.textColor,
                backgroundColor: theme.btnColor,
                fontFamily: "cardo",
              }}
              onClick={() => {
                navigate("/reservation");
              }}
            >
              Reservation
            </Button>
          </Box>
        </Toolbar>
        {pathHeader === "Spark Bird Tour" ? "" : <Header />}
      </Container>
    </AppBar>
  );
}
