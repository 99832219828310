import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import {
  ColumnContainer,
  ColumnStack,
  RowStack,
} from "../../../components/common/Container";
import { Title } from "../../../components/common/Text";
import { HorizontalLine } from "../../../components/common/Shape";
import Map from "../explore/Map";
import { Card, Container, Paper } from "@mui/material";
import ReserveCard from "./ReserveCard";
import TourInfo from "./TourInfo";
import { HotelOutlined } from "@mui/icons-material";
import GoogleMapCard from "./body_right/GoogleMapCard";

function TourPost() {
  return (
    <ColumnContainer>
      <ColumnStack>
        <Title style={{ textAlign: "left", width: "100%" }}>
          Winter Trip - 10 days
          <HorizontalLine style={{ marginTop: "10px" }} />
        </Title>
      </ColumnStack>
      <RowStack paddingX="30px">
        <Grid container spacing={1}>
          <Grid item xs={12} md={8} lg={8}>
            <Box
              component="img"
              loading="eager"
              alt="no image"
              src="https://loremflickr.com/640/480/nature"
              sx={{
                objectFit: "cover",
                width: "100%",
                overflow: "hidden",
                maxHeight: "480px",
                borderTopLeftRadius: "20px",
                borderBottomLeftRadius: "20px",
              }}
            />
          </Grid>
          <Grid
            item
            container
            direction="column"
            xs={12}
            md={4}
            lg={4}
            spacing={1}
          >
            <Grid item xs={12} lg={6} xl={6}>
              <Box
                component="img"
                loading="eager"
                alt="no image"
                src="https://loremflickr.com/640/480/nature"
                sx={{
                  width: "100%",
                  maxHeight: "236px",
                  objectFit: "cover",
                  borderTopRightRadius: "20px",
                }}
              />
            </Grid>
            <Grid item xs={12} lg={6} xl={6}>
              <Box
                component="img"
                loading="eager"
                alt="no image"
                src="https://loremflickr.com/640/480/nature"
                sx={{
                  width: "100%",
                  maxHeight: "236px",
                  objectFit: "cover",
                  borderBottomRightRadius: "20px",
                }}
              />
            </Grid>
          </Grid>
        </Grid>
        {/* 아래 */}
        <Grid container spacing={1}>
          <Grid item xl={8}>
            <TourInfo />
          </Grid>
          <Grid item xl={4}>
            <ReserveCard></ReserveCard>
            <GoogleMapCard key={"s"} />
          </Grid>
        </Grid>
      </RowStack>
    </ColumnContainer>
  );
}
export default TourPost;
