import styled from "styled-components";
import Filter from "../pages/explore/MonthFilter";
import Map from "../pages/explore/Map";
import { RecoilRoot, useRecoilState, useRecoilValue } from "recoil";
import { mapAreaAtom, mapSeasonAtom } from "../pages/explore/atom";
import {
  ColumnContainer,
  ColumnStack,
  RowStack,
} from "../../components/common/Container";
import { Title } from "../../components/common/Text";
import BirdCard from "../pages/birdpost/BirdCard";
import { useQuery } from "@tanstack/react-query";
import {
  IArea,
  IBird,
  IMonth,
  IPlace,
  getAllBirds,
  getAllPlaces,
  getAllAreas,
  getBirdsByPlaceId,
  getAllMonths,
  getPlacesByAreaId,
} from "../../api/exploreApi";
import { theme } from "../../theme-colors/theme";
import { HorizontalLine } from "../../components/common/Shape";
import SearchBar from "../../components/common/SearchBar";
import { useEffect, useState } from "react";
import PlaceCard from "../pages/placepost/PlaceCard";
import { Button } from "@mui/material";

/*
- 한국의 지역별 탐조 가능한 새, 스팟에 대한 포스트 
- 한국의 계절별 탐조 가능한 새, 스팟에 대한 포스트
- 각 포스트에서는 Our tours 링크로 이동이 가능해야 함
*/

interface IContent {
  isVisible: boolean;
}

export const VisibleContainer = styled(RowStack) <IContent>`
  width: 100%;
  overflow: hidden; /* 컴포넌트가 사라지면 숨겨진 영역을 가리키게 합니다. */
  transition: max-height 0.3s ease; /* max-height 속성에 애니메이션을 적용합니다. */
  visibility: ${({ isVisible }) => (isVisible ? "visible" : "hidden")};
  max-height: ${({ isVisible }) => (isVisible ? "1000px" : "0px")};
`;

function Explore() {
  // Map 표출용 - 지역, 월 조회
  const { isLoading: areaLoading, data: areaData } = useQuery<IArea[]>({
    queryKey: ["area"],
    queryFn: () => getAllAreas(),
  });

  const { isLoading: monthLoading, data: monthData } = useQuery<IMonth[]>({
    queryKey: ["month"],
    queryFn: () => getAllMonths(),
  });

  const [areas, setAreas] = useRecoilState(mapAreaAtom);
  const [months, setMonths] = useRecoilState(mapSeasonAtom);

  useEffect(() => {
    if (areaData) {
      let areas = areaData.map((area) => ({ ...area }));
      areas.map((area) => ((area.category = "Area"), (area.selected = false)));
      setAreas([...areas]);
    }
  }, [areaData]);

  // useEffect(() => {
  //   if (monthData) {
  //     let months = monthData.map((month) => ({ ...month }));
  //     months.map(
  //       (month) => ((month.category = "Season"), (month.selected = false))
  //     );
  //     setMonths([...months]);
  //   }
  // }, [monthData]);

  const mapArea = useRecoilValue<IArea[]>(mapAreaAtom);
  const mapSeason = useRecoilValue<IMonth[]>(mapSeasonAtom);

  const selectedArea = mapArea.filter((item) => item.selected);
  const selectedSeason = mapSeason.filter((item) => item.selected);

  const [isVisible, setIsVisible] = useState(false);

  useEffect(() => {
    if (selectedArea.length > 0 || selectedSeason.length > 0)
      setIsVisible(true);
  }, [mapArea, mapSeason]);

  const handleClick = () => {
    setIsVisible(!isVisible);
  };

  // Explore 표출용 - 새, 장소 조회

  // selectedArea 변화시 - 장소
  const [placeData, setPlaceData] = useState<IPlace[]>();
  const [showPlaceData, setShowPlaceData] = useState<IPlace[]>();
  const [numPlaceData, setNumPlaceData] = useState<number>(6);

  const { isLoading: placeLoading, data: fetchPlaceData } = useQuery<IPlace[]>({
    queryKey: ["placeAll"],
    queryFn: () => getAllPlaces(),
  });

  // 페이지 변경 시 데이터를 다시 불러오는 함수
  const handlePlacePageChange = () => {
    if (placeData && numPlaceData < placeData?.length + 3)
      setNumPlaceData(prevNum => prevNum + 3)
  };

  useEffect(() => {
    setShowPlaceData(placeData?.slice(0, numPlaceData))
  }, [placeData, numPlaceData])

  useEffect(() => {
    setPlaceData(fetchPlaceData);
  }, [placeLoading]);

  useEffect(() => {
    const fetchNewPlaces = async () => {
      const newPlaceData = await getPlacesByAreaId(
        selectedArea.map((area) => area.areaId)
      );
      setPlaceData(newPlaceData);
    };
    if (selectedArea.length > 0) {
      fetchNewPlaces();
    } else if (selectedArea.length == 0) {
      setPlaceData(fetchPlaceData);
    }
  }, [mapArea]);

  // placeData 변화시 - 새
  const [birdData, setBirdData] = useState<IBird[]>();
  const [showBirdData, setShowBirdData] = useState<IBird[]>();
  const [numBirdData, setNumBirdData] = useState<number>(6);


  const { isLoading: birdLoading, data: fetchBirdData } = useQuery<IBird[]>({
    queryKey: ["birdsAll"],
    queryFn: () => getAllBirds(),
  });

  // 페이지 변경 시 데이터를 다시 불러오는 함수
  const handleBirdPageChange = () => {
    if (birdData && numBirdData < birdData?.length + 3)
      setNumBirdData(prevNum => prevNum + 3)
  };

  useEffect(() => {
    setShowBirdData(birdData?.slice(0, numBirdData))
  }, [birdData, numBirdData])

  useEffect(() => {
    setBirdData(fetchBirdData);
  }, [birdLoading]);

  useEffect(() => {
    const fetchNewBirds = async () => {
      const newBirdData = await getBirdsByPlaceId(
        placeData?.map((place) => place.placeId)
      );
      setBirdData(newBirdData);
    };
    if (selectedArea.length > 0) {
      fetchNewBirds();
    } else if (selectedArea.length == 0) {
      setBirdData(fetchBirdData);
    }
  }, [placeData]);

  return (
    <ColumnContainer>
      <ColumnStack>
        <Title>Explore birdwatching tours!</Title>
        <HorizontalLine />
        <SearchBar onClick={handleClick} />
        <VisibleContainer
          isVisible={isVisible}
          style={{ padding: "5px 0 20px" }}
        >
          <Map />
          {/* <Filter data={mapSeason} /> */}
        </VisibleContainer>
      </ColumnStack>
      <ColumnStack style={{ backgroundColor: theme.blueColor }} padding="30px">
        <Title>You can watch these birds!</Title>
        <HorizontalLine />
        <RowStack
          justifyContent="space-evenly"
          paddingTop="20px"
          sx={{
            backgroundColor: theme.blueColor,
          }}
        >
          {showBirdData?.map((bird) => (
            <BirdCard data={bird}></BirdCard>
          ))}
        </RowStack>
        <Button
          onClick={handleBirdPageChange}>
          more ...
        </Button>
      </ColumnStack>

      <ColumnStack padding="30px">
        <Title>You can visit these places!</Title>
        <HorizontalLine />
        <RowStack justifyContent="space-evenly" paddingTop="20px">
          {showPlaceData?.map((place: IPlace) => (
            <PlaceCard data={place}></PlaceCard>
          ))}
        </RowStack>
        <Button
          onClick={handlePlacePageChange}>
          more ...
        </Button>
      </ColumnStack>
    </ColumnContainer>
  );
}
export default Explore;
