import * as React from "react";
import AspectRatio from "@mui/joy/AspectRatio";
import Avatar from "@mui/joy/Avatar";
import Box from "@mui/joy/Box";
import Card from "@mui/joy/Card";
import IconButton from "@mui/joy/IconButton";
import Typography from "@mui/joy/Typography";
import Link from "@mui/joy/Link";
import { useLocation, useNavigate } from "react-router-dom";
import { IArea, IPlace, getArea, getImageById } from "../../../api/exploreApi";
import { useQuery } from "@tanstack/react-query";
import { readImageFromS3 } from "../../../functions/readImageFromS3";

interface IPlaceProps {
  data: IPlace;
}

// https://mui.com/joy-ui/react-card/#resizable-container
function PlaceCard({ data }: IPlaceProps) {
  const { isLoading: imgLoading, data: images } = useQuery<string[]>({
    queryKey: [{data}],
    queryFn: () => getImageById(data.placeId),
  });
  const { isLoading: areaLoading, data: area } = useQuery<IArea>({
    queryKey: [data.areaId+"area"],
    queryFn: () => getArea(data.areaId),
  });
  const navigate = useNavigate();
  const onCardClick = () => {
    navigate(`/explore/places/${data.placeId}`, {
      state: { key: data.placeId },
    });
  };
  return (
    <Box sx={{ minHeight: 350 }} component="div" onClick={onCardClick}>
      <Card
        variant="outlined"
        sx={(theme) => ({
          width: 300,
          gridColumn: "span 2",
          flexDirection: "row",
          flexWrap: "wrap",
          resize: "horizontal",
          overflow: "hidden",
          gap: "clamp(0px, (100% - 360px + 32px) * 999, 16px)",
          transition: "transform 0.3s, border 0.3s",
          "&:hover": {
            borderColor: theme.vars.palette.primary.outlinedHoverBorder,
            transform: "translateY(-2px)",
          },
          "& > *": { minWidth: "clamp(0px, (360px - 100%) * 999,100%)" },
        })}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            gap: 2,
            maxWidth: 200,
          }}
        >
          <Box sx={{ display: "flex" }}>
            <div>
              <Typography level="title-lg">{data.name}</Typography>
              <Typography level="body-sm">{area?.name}</Typography>
            </div>
          </Box>
          <AspectRatio
            variant="soft"
            sx={{
              "--AspectRatio-paddingBottom":
                "clamp(0px, (100% - 200px) * 999, 200px)",
              pointerEvents: "none",
            }}
          >
            <img alt="" src={readImageFromS3(images!=null && images.length>0? images[0]:"logo/logo_w.png")} />
          </AspectRatio>
          <Typography level="body-sm">
            {" "}
            {data.around.length > 150
              ? `${data.around.slice(0, 150)}...`
              : data.around}
          </Typography>
        </Box>
      </Card>
    </Box>
  );
}
export default PlaceCard;
