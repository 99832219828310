import styled, { createGlobalStyle } from "styled-components";
import reset from "styled-reset";
import { HomeHeader } from "./layouts/HomeHeader";

const GlobalStyle = createGlobalStyle`
@import url('https://fonts.googleapis.com/css2?family=Source+Sans+3:ital,wght@0,200;1,200&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Cardo:ital,wght@0,400;0,700;1,400&display=swap');
  ${reset}
  body{
    font-family: "Source Sans 3", sans-serif;
    font-weight: 100;
    font-optical-sizing: auto;
    background-color: whitesmoke;
    color:${(props) => props.theme.textColor};
  }
  *{
    box-sizing: border-box;
  }
  a{
    text-decoration: none;
    color: inherit;
  }
`;

function Main() {
  return (
    <>
      <HomeHeader />
    </>
  );
}

export default Main;
