export const PhoneIcon = () => (
  <svg
    width="20"
    height="18"
    viewBox="0 0 30 31"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clip-path="url(#clip0_1_1433)">
      <path
        d="M18.8125 6.75C20.0334 6.98821 21.1555 7.58532 22.0351 8.46492C22.9147 9.34452 23.5118 10.4666 23.75 11.6875M18.8125 1.75C21.3491 2.0318 23.7145 3.16772 25.5203 4.97126C27.3261 6.7748 28.465 9.13876 28.75 11.675M27.5 21.65V25.4C27.5014 25.7481 27.4301 26.0927 27.2906 26.4117C27.1512 26.7307 26.9466 27.017 26.6901 27.2523C26.4336 27.4877 26.1307 27.6669 25.8009 27.7784C25.4712 27.8899 25.1217 27.9313 24.775 27.9C20.9285 27.4821 17.2338 26.1677 13.9875 24.0625C10.9673 22.1433 8.40668 19.5827 6.48751 16.5625C4.37498 13.3015 3.06031 9.58874 2.65001 5.725C2.61877 5.37933 2.65985 5.03095 2.77063 4.70203C2.88142 4.37311 3.05947 4.07086 3.29347 3.81453C3.52746 3.55819 3.81226 3.35339 4.12975 3.21315C4.44723 3.07292 4.79043 3.00033 5.13751 3H8.88751C9.49414 2.99403 10.0822 3.20885 10.5422 3.60442C11.0022 3.99998 11.3026 4.54931 11.3875 5.15C11.5458 6.35008 11.8393 7.52841 12.2625 8.6625C12.4307 9.10991 12.4671 9.59614 12.3674 10.0636C12.2677 10.5311 12.0361 10.9601 11.7 11.3L10.1125 12.8875C11.892 16.0169 14.4831 18.6081 17.6125 20.3875L19.2 18.8C19.5399 18.4639 19.969 18.2323 20.4364 18.1326C20.9039 18.0329 21.3901 18.0693 21.8375 18.2375C22.9716 18.6607 24.1499 18.9542 25.35 19.1125C25.9572 19.1982 26.5118 19.504 26.9082 19.9719C27.3046 20.4397 27.5152 21.037 27.5 21.65Z"
        stroke="#FFCA42"
        stroke-width="2"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </g>
    <defs>
      <clipPath id="clip0_1_1433">
        <rect
          width="30"
          height="30"
          fill="white"
          transform="translate(0 0.5)"
        />
      </clipPath>
    </defs>
  </svg>
);
