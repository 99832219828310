import Card from "@mui/joy/Card";
import CardCover from "@mui/joy/CardCover";
import CardContent from "@mui/joy/CardContent";
import Typography from "@mui/joy/Typography";
import Box from "@mui/material/Box";
import { theme } from "../../theme-colors/theme";
import { Helmet } from "react-helmet";

export function HomeHeader() {
  const { innerWidth: width, innerHeight: height } = window;
  return (
    <>
      <Helmet>
        <title>Welcome to Spark Bird Tour!</title>
      </Helmet>
      <Card
        component="div"
        sx={{
          width: "100%",
          height: height * 0.9,
          display: "flex",
          flexDirection: "column",
          backgroundSize: "cover",
          backgroundPosition: "center",
        }}
      >
        <CardCover>
          <video autoPlay loop muted>
            <source src="./store/bikal.mov" type="video/mp4" />
          </video>
        </CardCover>
        <CardContent sx={{ alignItems: "center" }}>
          <Box
            sx={{
              position: "absolute",
              bottom: 0,
              left: 0,
              width: "100%",
              bgcolor: "rgba(0, 0, 0, 0.34)",
              color: "white",
              padding: "10px",
              alignItems: "center",
            }}
          >
            <Typography
              level="body-lg"
              fontWeight="md"
              textColor="#fff"
              fontSize={40}
              fontFamily="serif"
              mt={{ xs: 12, sm: 18, xl: 0 }}
              sx={{ color: theme.blueColor }}
            >
              Spark Bird Tour
            </Typography>
            <Typography
              level="body-xs"
              fontSize={20}
              fontFamily="serif"
              mt={3}
              sx={{ color: theme.blueColor }}
            >
              Embarking on an Adventure into Nature, Bonding with Wildlife: Our
              Journey of Sustainable Exploration, Protecting the Environment and
              Cherishing Encounters with Animals.
            </Typography>
          </Box>
        </CardContent>
      </Card>
    </>
  );
}
