import { ColumnContainer, ColumnStack } from "../../components/common/Container";
import { HorizontalLine } from "../../components/common/Shape";
import { Title } from "../../components/common/Text";
import TourCard from "./../pages/tour/TourCard";
import Typography from "@mui/joy/Typography";
import * as React from "react";
function Tour() {
  return (
    <ColumnContainer>
      <ColumnStack paddingBottom="100px">
        <Title>Our Trips</Title>
        <HorizontalLine />
        <TourCard />
        <TourCard />
        <TourCard />
      </ColumnStack>
    </ColumnContainer>
  );
}
export default Tour;
