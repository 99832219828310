import AWS from 'aws-sdk';
import { awsConfig } from '../aws-config';

AWS.config.update({
  region: awsConfig.region,
  accessKeyId: awsConfig.accessKeyId,
  secretAccessKey: awsConfig.secretAccessKey,
});

const s3 = new AWS.S3();

export const readImageFromS3 = (imageKey: string): string => {
  const params = {
    Bucket: awsConfig.bucketName,
    Key: imageKey,
  };
  return s3.getSignedUrl('getObject', params);
};