import * as React from "react";
import Box from "@mui/material/Box";
import Divider from "@mui/material/Divider";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import dayjs, { Dayjs } from "dayjs";
import Slider from "@mui/material/Slider";
import { Typography } from "@mui/material";
import Fade from "@mui/material/Fade";
import getPathHeader from "../../functions/getPathHeader";
import { useLocation } from "react-router-dom";

// drawer  https://mui.com/material-ui/react-drawer/#responsive-drawer
// list https://mui.com/material-ui/react-list/
const drawerWidth = 240;

function SideDrawer() {
  const [startDate, setStartDate] = React.useState<Dayjs | null>();
  const [endDate, setEndDate] = React.useState<Dayjs | null>();

  const [value, setValue] = React.useState<number[]>([0, 500]);
  const handleChange = (event: Event, newValue: number | number[]) => {
    setValue(newValue as number[]);
  };

  const location = useLocation();
  const pathHeader = getPathHeader(location.pathname);
  return (
    <Fade in={pathHeader === "TOUR" ? true : false}>
      <Box sx={{ paddingTop: 10 }}>
        <Box
          sx={{
            width: "100%",
            maxWidth: 280,
            bgcolor: "background.paper",
            m: 1,
            border: 1,
            borderRadius: "15px",
            padding: 2,
          }}
        >
          <List>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <ListItemText
                sx={{ my: 0 }}
                primary="When are you traveling?"
                primaryTypographyProps={{
                  fontSize: 20,
                  fontWeight: "medium",
                  letterSpacing: 0,
                }}
              />
              <ListItem>
                <DatePicker
                  label="From"
                  value={startDate}
                  onChange={(newDate) => setStartDate(newDate)}
                />
              </ListItem>
              <ListItem>
                <DatePicker
                  label="To"
                  value={endDate}
                  onChange={(newDate) => setEndDate(newDate)}
                />
              </ListItem>
            </LocalizationProvider>
            <Divider
              sx={{
                marginY: 1,
              }}
            />
            <ListItemText
              sx={{ my: 0 }}
              primary="Price"
              primaryTypographyProps={{
                fontSize: 20,
                fontWeight: "medium",
                letterSpacing: 0,
              }}
            />
            <ListItem
              sx={{
                display: "flex",
                justifyContent: "space-between;",
              }}
            >
              <Typography>${value[0]}</Typography>
              <Typography>${value[1]}</Typography>
            </ListItem>
            <ListItem>
              <Slider
                value={value}
                onChange={handleChange}
                aria-labelledby="input-slider"
                valueLabelDisplay="auto"
                max={500}
                color="success"
              />
            </ListItem>
          </List>
        </Box>
      </Box>
    </Fade>
  );
}
export default SideDrawer;
