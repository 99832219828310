import {
  ColumnContainer,
  ColumnStack,
  RowStack,
} from "../../../components/common/Container";
import { HorizontalLine } from "../../../components/common/Shape";
import { Title } from "../../../components/common/Text";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import { useNavigate, useParams } from "react-router-dom";
import { useQuery } from "@tanstack/react-query";
import { IBird, IPlace, getAllBirds, getBirdsByPlaceId, getImageById, getPlaceById } from "../../../api/exploreApi";
import {
  Button,
  Card,
  CardMedia,
  Container,
  Icon,
  Stack,
  Typography,
} from "@mui/material";
import LocalSeeIcon from "@mui/icons-material/LocalSee";
import ApprovalIcon from "@mui/icons-material/Approval";
import AccessTimeIcon from "@mui/icons-material/AccessTime";
import { theme } from "../../../theme-colors/theme";
import BirdCard from "../birdpost/BirdCard";
import { readImageFromS3 } from "../../../functions/readImageFromS3";

function PlacePost() {
  let { placeId } = useParams<string>();
  const { isLoading: placeLoading, data: placeData } = useQuery<IPlace>({
    queryKey: ["palceInfo"],
    queryFn: () => getPlaceById(placeId ? placeId : ""),
  });
  const { isLoading: infoLoading, data: birdData } = useQuery<IBird[]>({
    queryKey: ["birds"],
    queryFn: () => getBirdsByPlaceId(placeId ? [placeId] : []),
  });
  const { isLoading: imgLoading, data: images } = useQuery<string[]>({
    queryKey: ["images"],
    queryFn: () => getImageById(placeId),
  });

  const navigate = useNavigate();

  return (
    <ColumnContainer>
      <ColumnStack>
        <Card sx={{ width: "100%" }}>
          <Box sx={{ position: "relative" }}>
            <Box
              component="img"
              loading="eager"
              alt={placeData?.name}
              src={readImageFromS3(images ? images[0] : "logo/logo_w.png")}
              sx={{
                display: "flex",
                objectFit: "cover",
                width: "100%",
                maxHeight: 350,
              }}
            />
            <Box
              sx={{
                position: "absolute",
                bottom: 0,
                left: 0,
                width: "100%",
                bgcolor: "rgba(0, 0, 0, 0.34)",
                color: "white",
                padding: "10px",
              }}
            >
              <Title>{placeData?.name}</Title>
            </Box>
          </Box>
        </Card>
        <Grid container spacing={5} padding="20px">
          <Grid item xs={3}>
            <Box padding={1}>
              <Typography fontWeight={600}>When to visit</Typography>
              <Typography>{placeData?.visitTime}</Typography>
            </Box>
          </Grid>
          <Grid item xs={3}>
            <Box padding={1}>
              <Typography fontWeight={600}>Getting around</Typography>
              <Typography>{placeData?.around}</Typography>
            </Box>
          </Grid>
          <Grid item xs={3}>
            <Box padding={1}>
              <Typography fontWeight={600}>Traveler tips</Typography>
              <Typography>{placeData?.tips}</Typography>
            </Box>
          </Grid>
          <Grid item xs={3}>
            <Grid container rowGap={2}>
              <Grid container columnSpacing={3}>
                <Grid item xs={1}>
                  <LocalSeeIcon />
                </Grid>
                <Grid item xs={11}>
                  <Typography> You can watch</Typography>
                </Grid>
                <Grid item xs={1}></Grid>
                <Grid item xs={11}>
                  <Typography>{placeData?.numBirds}</Typography>
                </Grid>
              </Grid>
              <Grid container columnSpacing={3}>
                <Grid item xs={1}>
                  <ApprovalIcon />
                </Grid>
                <Grid item xs={11}>
                  <Typography>Level</Typography>
                </Grid>
                <Grid item xs={1}></Grid>
                <Grid item xs={11}>
                  <Typography>{placeData?.level}</Typography>
                </Grid>
              </Grid>
              <Grid container columnSpacing={3}>
                <Grid item xs={1}>
                  <AccessTimeIcon />
                </Grid>
                <Grid item xs={11}>
                  <Typography>Hours</Typography>
                </Grid>
                <Grid item xs={1}></Grid>
                <Grid item xs={11}>
                  <Typography>{placeData?.hours}H</Typography>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>

        <ColumnContainer
          style={{
            backgroundColor: theme.blueColor,
          }}
        >
          <ColumnStack sx={{ paddingY: "30px" }}>
            <Title>Birds live in {placeData?.name}</Title>
            <HorizontalLine />

            <Box
              sx={{
                display: "flex",
                gap: 1,
                py: 1,
                overflow: "auto",
                width: "90%",
                scrollSnapType: "x mandatory",
                "& > *": {
                  scrollSnapAlign: "center",
                },
                "::-webkit-scrollbar": { display: "none" },
              }}
            >
              {birdData?.map((bird) => (
                <BirdCard data={bird}></BirdCard>
              ))}
            </Box>
          </ColumnStack>
          <ColumnStack
            padding="40px 0"
            minHeight="300px"
            gap="20px"
            sx={{
              backgroundColor: theme.btnColor,
            }}
          >
            <Typography
              sx={{
                fontSize: "25px",
                fontFamily: "Cardo",
              }}
            >
              Do you want to join the Spark Bird Tour's tour?
            </Typography>
            <Button
              style={{
                backgroundColor: theme.textColor,
                fontFamily: "Cardo",
              }}
              variant="contained"
              onClick={() => {
                navigate(`/tour`);
              }}
            >
              Yes!
            </Button>
          </ColumnStack>
        </ColumnContainer>
      </ColumnStack>
    </ColumnContainer>
  );
}

export default PlacePost;
