import { MapTheme } from "styled-components";

//colorhunt.co/palette/49698958a399a8cd9fe2f4c5
export const mapTheme: MapTheme = {
  green: "#58A399",
  mapselect: "#86BB9C",
  mapover: "#A8CD9F",
  mapbg: "#E2F4C5",
  mapfilter: "#D6E4AA",
  blue: "#89A3B2",
};
