import React from "react";

import Timeline from "@mui/lab/Timeline";
import TimelineItem, { timelineItemClasses } from "@mui/lab/TimelineItem";
import TimelineSeparator from "@mui/lab/TimelineSeparator";
import TimelineConnector from "@mui/lab/TimelineConnector";
import TimelineContent from "@mui/lab/TimelineContent";
import TimelineDot from "@mui/lab/TimelineDot";
import { Typography } from "@mui/material";
import { theme } from "../../../../theme-colors/theme";

// https://mui.com/material-ui/react-timeline/

export const itinerary_data = [
  {
    id: "1",
    geometry: {
      lat: 37.458666,
      lng: 126.4419679,
    },
    location: "Arrival in Seoul",
    description:
      "We'll gather in Incheon International Airport and move to Seoul.",
  },
  {
    id: "2",
    geometry: {
      lat: 37.52707290649414,
      lng: 127.01984405517578,
    },
    location: "Hangang (Han) Estuary & Imjin River",
    description:
      "We’ll spend a day visiting the environs of the wide Han River Estuary and its confluence with the south flowing Imjin River. Highlights to be found as we meander the mudflats and scrub may include Smew, Brown-cheeked Rail, both White-naped and Red-crowned Cranes, White-tailed Eagle, the rare, but seemingly annual Steller's SeaEagle, Grey-capped Pygmy and Japanese Pygmy Woodpeckers, Bullheaded Shrike, Azure-winged Magpie, Dusky and Naumann's Thrushes, Daurian Redstart, Japanese Wagtail, and both Yellowthroated and Rustic Buntings.",
  },
  {
    id: "3",
    geometry: {
      lat: 37.891544342041016,
      lng: 126.74361419677734,
    },
    location: "Cheorwon-gun, the DMZ & cranes",
    description:
      "This historic site of ancient fortifications is located close to the DMZ, allowing for excellent views over the river below. However, our pirmary reason for being here is to witness the flypast of several species of cranes. Roosting across the border in North Korea, the cranes start moving south in the morning to feed on the exapansive Cheorwon-gun Plain, flying close by to where we’ll be standing. Very small numbers of Sandhill, Common and Hooded Cranes are often found in the area, and there is even an outside chance for Siberian Cranes. Large numbers of Cinereous Vultures can be expected overhead, along with skeins of wildfowl containing Taiga and Tundra Bean, Greater White-fronted and even a slight chance of Lesser White-fronted Goose. The river below holds large numbers of duck, primarily Mallards and Goosanders, whils the rougher water may turn up our first Scaly-sided Merganser. Several White-tailed Eagles are likely to be patrolling the icy waters, occassionally flushing flocks of waterfowl. Red-crowned Cranes by David Hoddinott Swan Goose by Sarah Thomas Rockjumper Birding Tours Page | 4 The roadside scrub often holds the tricky Naumann's Thrush, Durian Redstart, Chinese Grey Shrike, Bullheaded Shrike, rare Long-tailed Rosefinch or even a few Hawfinches, whilst the extensive stubble fields have small flock of Rustic and Yellowthroated Buntings, common Oriental Greenfinch and Brambling.",
  },
  {
    id: "4",
    geometry: {
      lat: 37.75421142578125,
      lng: 127.16526794433594,
    },
    location: "National Arboretum & Han River",
    description:
      "The picturesque gardens offer a great introduction to open woodland birding. Varied Tits have been habituated by one of the wardens, often coming very close if nuts are on offer. Our primary reason for being here is to target the tricky Solitary Snipe, a small stream running through the gardens being one of the best sites in the world to see it. A supporting cast of Brown-cheeked Bulbul, Japanese Pygmy Woodpecker, Daurian Redstart, Hawfinch, Yellow-throated Bunting, Vinous-throated Parrotbill will keep us entertained. With luck, we could stumble onto a rarity such as Pallas's Rosefinch or even Siberian Accentor, whilst Black Woodpecker or Yellow-bellied Tit would be an excellent return. The nearby Han River is a reasonably reliable stakeout for Scaly-sided Merganser and, in recent years at least, Steller's Sea Eagle has reliably wintered here. We can also expect to see vast numbers of Whooper Swan and both Taiga and Tundra Bean Geese.",
  },
  {
    id: "5",
    geometry: {
      lat: 35.55159378051758,
      lng: 128.41200256347656,
    },
    location: "Upo Wetland",
    description:
      "This largest wetland complex has large numbers of wintering wildfowl such as Gadwall, Eurasian Wigeon, both Tundra and Taiga Bean Geese, Eurasian Spoonbill, Tundra and Whooper Swans and the odd Mandarin Duck.",
  },
  {
    id: "6",
    geometry: {
      lat: 34.92851638793945,
      lng: 127.4982681274414,
    },
    location: "Suncheon Bay",
    description:
      "This areas is host to another crane spectacle, with hundreds of Hooded Cranes feeding in the stubble fields. Careful scanning will likely find a small scattering of Common Cranes, and if we are lucky, a few Sandhill Cranes amongt them. Overhead, we should find Eastern, Upland and Rough-legged Buzzards, as well as Hen Harriers courting the fields. We’ll try to entice a few Chinese Penduline Tits from the thick reedbeds. Nearby wetlands typically hold small numbers of Baikal Teal and Chinese Grey Shrike is possible.",
  },
  {
    id: "7",
    geometry: {
      lat: 36.00012969970703,
      lng: 126.6666030883789,
    },
    location: "Geum River Estuary",
    description:
      "We head for the ginormous Baikal Teal statue to give ourselves a good perch from which to gaze over the river barrage below for real Baikal Teals! If our luck is in, we could see flocks numbering many thousands of this otherwise elusive species. There are likely to be large flocks of Common Pochards and Northern Pintails, and possibly a Falcated Duck or two.",
  },
  {
    id: "8",
    geometry: {
      lat: 36.604515075683594,
      lng: 126.42070770263672,
    },
    location: "Seosan Cheonsu Bay",
    description:
      "One of the top birding sites in Korea, we will spend time targeting Oriental Stork or perhaps Relict Gull and Chinese Grey Shrike if we haven’t seen them yet. We all also enjoy the numerous Hooded Cranes feeding in the area whilst poking about in the reed beds for Pallas’s and Common Reed Buntings. Scrubby patches may have Black-faced Bunting, and scarcities here may include Dusky Warbler.",
  },
  {
    id: "9",
    geometry: {
      lat: 37.71269226074219,
      lng: 126.45088195800781,
    },
    location: "Ganghwa Island",
    description:
      "The tidal flats and rice fields are an excellent site for Red-crowned and White-naped Cranes, as well as Mandarin and Falcated Ducks, Red-breasted Merganser, White-tailed Eagle, Azure-winged Magpie, Long-tailed Rosefinch, Oriental Greenfinch, Meadow and Yellow-throated Buntings and the oaccsaional Rustic Bunting.",
  },
];

export default function Itinerary() {
  return (
    <Timeline
      sx={{
        [`& .${timelineItemClasses.root}:before`]: {
          flex: 0,
          padding: 0,
        },
      }}
    >
      {itinerary_data.map((data) => (
        <TimelineItem>
          <TimelineSeparator>
            <TimelineDot
              sx={{
                width: "30px",
                height: "30px",
                justifyContent: "center",
                alignItems: "center",
                font: "icon",
                backgroundColor: theme.blueColor,
                color: theme.textColor,
              }}
            >
              {data.id}
            </TimelineDot>
            <TimelineConnector />
          </TimelineSeparator>
          <TimelineContent>
            <Typography sx={{ fontWeight: 600 }}>{data.location}</Typography>
            <Typography>{data.description}</Typography>
            <img
              src={`https://picsum.photos/id/${data.id}00/200/100`}
              alt="no image"
              width="100%"
              style={{ padding: "20px" }}
            />
          </TimelineContent>
        </TimelineItem>
      ))}
    </Timeline>
  );
}
