import { useQuery } from "@tanstack/react-query";
import BirdListForPlace from "./BirdListForPlace";
import { IBird, IPlace, getAllBirds, getAllPlaces } from "../../../api/exploreApi";
import { useState } from "react";
import PlaceListForBird from "./PlaceListForBird";

export default function BirdPlaceRegister() {

    const { isLoading: placeLoading, data: fetchPlaceData, refetch: refetchPlace } = useQuery<IPlace[]>({
        queryKey: ["placesAll"],
        queryFn: () => getAllPlaces(),
    });
    // place, bird 연결
    const [selectedPlace, setSelectedPlace] = useState<string>("");
    const handleRowClickPlace = (params: string) => {
        console.log(params)
        setSelectedPlace(params);
    };
    const [selectedBirds, setSelectedBirds] = useState<string[] | null>(null);
    const handleRowClickBirds = (params: string[]) => {
        setSelectedBirds(params);
    };
    return (
        <div>
            <div style={{ display: "flex", justifyContent: "center" }}>
                <PlaceListForBird places={fetchPlaceData} handleRowClick={handleRowClickPlace} />
            </div>
            <div style={{ display: "flex", justifyContent: "center" }}>
                <BirdListForPlace key={selectedPlace} placeId={selectedPlace} handleRowClick={handleRowClickBirds} />
            </div>
        </div>
    );
}