import styled from "styled-components";
import Typography from "@mui/joy/Typography";
import AspectRatio from "@mui/joy/AspectRatio";
import { useLocation, useNavigate } from "react-router-dom";
import { IBird, IPhoto, getImageById } from "../../../api/exploreApi";
import Card from "@mui/joy/Card";
import Box from "@mui/joy/Box";
import { readImageFromS3 } from "../../../functions/readImageFromS3";
import { useQuery } from "@tanstack/react-query";

type BirdCardProps = {
  data: IBird;
};

//https://uiverse.io/Qasimsaifi/brown-cheetah-82
function BirdCard({ data }: BirdCardProps) {
  const { isLoading: imgLoading, data: images } = useQuery<string[]>({
    queryKey: [{ data }],
    queryFn: () => getImageById(data.birdId),
  });

  const navigate = useNavigate();
  const onCardClick = () => {
    navigate(`/explore/birds/${data.birdId}`, { state: { key: data.birdId } });
  };

  return (
    <Box sx={{ minHeight: 350 }} component="div" onClick={onCardClick}>
      <Card
        variant="outlined"
        sx={(theme) => ({
          width: 300,
          height: 400,
          gridColumn: "span 2",
          flexDirection: "row",
          flexWrap: "wrap",
          resize: "horizontal",
          overflow: "hidden",
          gap: "clamp(0px, (100% - 360px + 32px) * 999, 16px)",
          transition: "transform 0.3s, border 0.3s",
          cursor: "pointer",
          "&:hover": {
            borderColor: theme.vars.palette.primary.outlinedHoverBorder,
            transform: "translateY(-2px)",
          },
          "& > *": { minWidth: "clamp(0px, (360px - 100%) * 999,100%)" },
        })}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            gap: 2,
            maxWidth: 200,
            padding: "0 0 5px 0",
          }}
        >
          <Box sx={{ display: "flex" }}>
            <Typography level="title-lg">{data.name}</Typography>
          </Box>
          <AspectRatio
            variant="soft"
            sx={{
              "--AspectRatio-paddingBottom":
                "clamp(0px, (100% - 200px) * 999, 200px)",
              pointerEvents: "none",
            }}
          >
            <img alt="" src={readImageFromS3(images != null && images.length > 0 ? images[0] : "logo/logo_w.png")} />
          </AspectRatio>

          <Typography level="body-sm">
            {" "}
            {data.content.length > 150
              ? `${data.content.slice(0, 150)}...`
              : data.content}
          </Typography>
        </Box>
      </Card>
    </Box>
  );
}
export default BirdCard;
