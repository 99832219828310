import { atom } from "recoil";
import { IArea, IMonth } from "../../../api/exploreApi";

export const mapSeasonAtom = atom<IMonth[]>({
  key: "mapSeason",
  default: [],
});

export let mapAreaAtom = atom<IArea[]>({
  key: "mapArea",
  default: [],
});
