import { Outlet, useLocation, useNavigate } from "react-router-dom";
import styled, { createGlobalStyle } from "styled-components";
import reset from "styled-reset";
import Footer from "./layouts/Footer";
import { RecoilRoot } from "recoil";
import SideDrawer from "./layouts/SideDrawer";
import Grid from "@mui/material/Grid";
import getPathHeader from "../functions/getPathHeader";
import { HomeHeader } from "./layouts/HomeHeader";
import * as React from "react";
import { NavigationBar } from "./layouts/NavigationBar";

const GlobalStyle = createGlobalStyle`
@import url('https://fonts.googleapis.com/css2?family=Source+Sans+3:ital,wght@0,200;1,200&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Cardo:ital,wght@0,400;0,700;1,400&display=swap');
  ${reset}
  body{
    font-family: "Source Sans 3", sans-serif;
    font-weight: 100;
    font-optical-sizing: auto;
    background-color: whitesmoke;
    color:${(props) => props.theme.textColor};
  }
  *{
    box-sizing: border-box;
  }
  a{
    text-decoration: none;
    color: inherit;
  }
`;
const Container = styled.div`
  background-color: white;
  background-color: ${(props) => props.theme.lightColor};
  position: relative;
  padding-top: 5%;
  @media (max-width: 950px) {
    margin: 0;
  }
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: ${window.innerHeight - 700 + 1}px;
`;

function Root() {
  const location = useLocation();
  const pathHeader = getPathHeader(location.pathname);
  return (
    <RecoilRoot>
      <GlobalStyle />
      <>
        <header>
          <NavigationBar />
        </header>
        {pathHeader === "Spark Bird Tour" ? (
          <HomeHeader />
        ) : (
          <Grid container spacing={0}>
            <Grid item xs={2} justifyContent="flex-end" display={"flex"}>
              <SideDrawer />
            </Grid>
            <Grid item xs={8}>
              <Container>
                <Outlet />
              </Container>
            </Grid>
            <Grid item xs={2}></Grid>
          </Grid>
        )}
      </>

      <footer>
        <Footer />
      </footer>
    </RecoilRoot>
  );
}
export default Root;
