import React from "react";
import styled from "styled-components";
import { useEffect, useState } from "react";
import { SouthKoreaSvgMap } from "./SouthKoreaSvgMap";
import { mapAreaAtom } from "../atom";
import { useRecoilValue, useSetRecoilState } from "recoil";
import { IArea } from "../../../../api/exploreApi";

interface ITooltipStyle {
  display: string;
  top?: number;
  left?: number;
}

const DefaultTooltip = styled.div<ITooltipStyle>`
  border-radius: 10px;
  color: #41444a;
  position: fixed;
  min-width: 80px;
  padding: 10px;
  background-color: ${(props) => props.theme.blueColor};
  border: 1px solid ${(props) => props.theme.textColor};
  display: ${(props) => props.display};
  top: ${(props) => props.top}px;
  left: ${(props) => props.left}px;
`;

export const SimpleSouthKoreaMapChart = () => {
  const data = useRecoilValue<IArea[]>(mapAreaAtom);
  const setMapAreaAtom = useSetRecoilState(mapAreaAtom);

  const [tooltipMsg, setTooltipMsg] = useState<string>("");
  const [tooltipStyle, setTooltipStyle] = useState<ITooltipStyle>({
    display: "none",
    top: 0,
    left: 0,
  });

  const handleAreaMouseOver = (event: any) => {
    const area = event.target.attributes.name.value.toString();
    const overArea = data.find((obj) => obj.name === area);
    const count = overArea ? overArea.birdCount : 0;
    setTooltipMsg(`${area}: ${count}`);
  };

  const handleAreaMouseOut = (event: any) => {
    setTooltipStyle({ display: "none" });
  };

  const handleAreaMouseMove = (event: any) => {
    const tooltipStyle = {
      display: "block",
      top: event.clientY - 50,
      left: event.clientX - 60,
    };
    setTooltipStyle(tooltipStyle);
  };

  const handleAreaClick = (event: any) => {
    const area = event.target.attributes.name.value;

    const newArea = data.map((item) => {
      if (item.name === area) {
        return { ...item, selected: !item.selected };
      }
      return item; // 변경 대상이 아닌 경우 기존 요소 그대로 유지
    });
    setMapAreaAtom(newArea);
  };

  return (
    <>
      <SouthKoreaSvgMap
        data={data}
        onAreaMouseOver={handleAreaMouseOver}
        onAreaMouseOut={handleAreaMouseOut}
        onAreaMouseMove={handleAreaMouseMove}
        onAreaClick={handleAreaClick}
      />
      <DefaultTooltip {...tooltipStyle}>{tooltipMsg}</DefaultTooltip>
    </>
  );
};
