import * as React from "react";
import AccordionGroup from "@mui/joy/AccordionGroup";
import Accordion, { accordionClasses } from "@mui/joy/Accordion";
import AccordionDetails from "@mui/joy/AccordionDetails";
import AccordionSummary from "@mui/joy/AccordionSummary";
import { theme } from "../../../theme-colors/theme";
import { Box, Card, CardContent, Icon, Typography } from "@mui/material";
import TypographyJoy from "@mui/joy/Typography";

import Itinerary from "./body_left/Itinerary";
import { ColumnStack, RowStack } from "../../../components/common/Container";
import RestaurantIcon from "@mui/icons-material/Restaurant";
import MapsHomeWorkIcon from "@mui/icons-material/MapsHomeWork";
import DirectionsBusIcon from "@mui/icons-material/DirectionsBus";
// https://mui.com/joy-ui/react-accordion/#controlled-accordion
export default function TourInfo() {
  return (
    <AccordionGroup
      sx={{
        [`& .${accordionClasses.root}`]: {
          fontSize: "20px",
          transition: "0.2s ease",

          '& button:not([aria-expanded="true"])': {
            transition: "0.2s ease",
            paddingBottom: "10px",
          },
          "& button:hover": {
            background: "transparent",
          },
        },
        [`& .${accordionClasses.root}.${accordionClasses.expanded}`]: {
          //   bgcolor: theme.blueColor,
          border: "none",
          color: theme.textColor,
          marginBottom: "10px",
        },
        '& [aria-expanded="true"]': {
          padding: "10px",
          fontSize: "25px",
          boxShadow: (theme) => `inset 0 -1px 0 ${theme.vars.palette.divider}`,
        },
      }}
    >
      <Accordion expanded>
        <AccordionSummary>Overview</AccordionSummary>
        <AccordionDetails>
          <Typography
            component="pre"
            paragraph={true}
            whiteSpace="pre-wrap"
            color={theme.textColor}
          >
            South Korea is a key wintering ground for several migratory species,
            many of whom are found in very large numbers. These great birding
            spectacles are set against a backdrop of snowy mountains, pine
            forests, sweeping coastal wetlands and estuaries. {"\n"}
            We can look forward to enjoying impressive gatherings of wildfowl
            with vast flocks of geese and cranes, colonies of various gulls and
            rafts of alcids just offshore. Often overlooked for their more
            illustrious neighbours of Japan and China, South Korea offers
            equally exceptional birding in a far more condescend area, with top
            class infrastructure and enviable safety. {"\n"}
            The country's lack of bird persecution contributes to the abundance
            of birds, providing for repeat, quality sightings of sought-after
            species instead of fleeting glimpses. Highlights are sure to include
            stunning encounters with the rare Scaly-sided Merganser, large
            flocks of Red-crowned, White-naped and Hooded Cranes, a chance for
            Steller’s Sea Eagle, numerous Baikal Teal, Swan Goose, Oriental
            Stork, Solitary Snipe, Azure-winged Magpie, Chinese Grey Shrike,
            Chinese Penduline Tit and Relict Gull."
          </Typography>
        </AccordionDetails>
      </Accordion>
      <Accordion>
        <AccordionSummary>Itinerary</AccordionSummary>
        <AccordionDetails>
          <Itinerary />
        </AccordionDetails>
      </Accordion>
      <Accordion>
        <AccordionSummary>What to expect</AccordionSummary>
        <AccordionDetails>
          <Typography
            component="pre"
            paragraph={true}
            whiteSpace="pre-wrap"
            color={theme.textColor}
          >
            Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
            eiusmod tempor incididunt ut labore et dolore magna aliqua.
          </Typography>
        </AccordionDetails>
      </Accordion>
      <Accordion>
        <AccordionSummary>What's included</AccordionSummary>
        <AccordionDetails>
          <RowStack sx={{ paddingTop: "10px" }}>
            <Card sx={{ width: "200px", alignItems: "center" }}>
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  gap: 2,
                  backgroundColor: theme.blueColor,
                }}
              >
                <CardContent
                  sx={{
                    textAlign: "center",
                  }}
                >
                  <RestaurantIcon />
                  <TypographyJoy level="title-lg">Food</TypographyJoy>
                  <TypographyJoy level="body-sm">
                    Three meals are provided everyday.
                  </TypographyJoy>
                </CardContent>
              </Box>
            </Card>
            <Card sx={{ width: "200px", alignItems: "center" }}>
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  gap: 2,
                  backgroundColor: theme.blueColor,
                }}
              >
                <CardContent
                  sx={{
                    textAlign: "center",
                  }}
                >
                  <MapsHomeWorkIcon />
                  <TypographyJoy level="title-lg">Accommodation</TypographyJoy>
                  <TypographyJoy level="body-sm">
                    Comfortable rooms are provided.
                  </TypographyJoy>
                </CardContent>
              </Box>
            </Card>

            <Card sx={{ width: "200px", alignItems: "center" }}>
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  gap: 2,
                  backgroundColor: theme.blueColor,
                }}
              >
                <CardContent
                  sx={{
                    textAlign: "center",
                  }}
                >
                  <DirectionsBusIcon />
                  <TypographyJoy level="title-lg">
                    Roundtrip Trasnfer{" "}
                  </TypographyJoy>
                  <TypographyJoy level="body-sm">
                    Safe and convenient buses are provided.
                  </TypographyJoy>
                </CardContent>
              </Box>
            </Card>
          </RowStack>
        </AccordionDetails>
      </Accordion>

      <Accordion>
        <AccordionSummary>Cancellation policy</AccordionSummary>
        <AccordionDetails>
          <Typography
            component="pre"
            paragraph={true}
            whiteSpace="pre-wrap"
            color={theme.textColor}
          >
            Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
            eiusmod tempor incididunt ut labore et dolore magna aliqua.
          </Typography>
        </AccordionDetails>
      </Accordion>
      <Accordion>
        <AccordionSummary>FAQ</AccordionSummary>
        <AccordionDetails>
          <Typography
            component="pre"
            paragraph={true}
            whiteSpace="pre-wrap"
            color={theme.textColor}
          >
            Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
            eiusmod tempor incididunt ut labore et dolore magna aliqua.
          </Typography>
        </AccordionDetails>
      </Accordion>
      <Accordion>
        <AccordionSummary>Help</AccordionSummary>
        <AccordionDetails>
          <Typography
            component="pre"
            paragraph={true}
            whiteSpace="pre-wrap"
            color={theme.textColor}
          >
            Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
            eiusmod tempor incididunt ut labore et dolore magna aliqua.
          </Typography>
        </AccordionDetails>
      </Accordion>
    </AccordionGroup>
  );
}
