import * as React from "react";
import AspectRatio from "@mui/joy/AspectRatio";
import Button from "@mui/joy/Button";
import Card from "@mui/joy/Card";
import CardContent from "@mui/joy/CardContent";
import IconButton from "@mui/joy/IconButton";
import Typography from "@mui/joy/Typography";
import BookmarkAdd from "@mui/icons-material/BookmarkAddOutlined";
import { theme } from "../../../theme-colors/theme";
import Select from "@mui/joy/Select";
import Option from "@mui/joy/Option";
import Stack from "@mui/joy/Stack";
import Box from "@mui/joy/Box";
import Add from "@mui/icons-material/Add";
import Remove from "@mui/icons-material/Remove";
import { useState } from "react";

export default function ReserveCard() {
  const [count, setCount] = useState(0);
  return (
    <Card sx={{ width: "100%" }}>
      <Typography level="title-lg">From $10,000</Typography>
      <Typography level="body-sm">Select date options</Typography>
      <Stack spacing={2} alignItems="flex-start">
        <Select placeholder="Date" name="foo" required sx={{ width: "100%" }}>
          <Option value="dog">11/11 ~ 11/25</Option>
          <Option value="cat">11/20 ~ 12/5</Option>
          <Option value="fish">12/01 ~ 12/15</Option>
          <Option value="bird">12/10 ~ 12/25</Option>
        </Select>
      </Stack>
      <Typography level="body-sm">Select travelers</Typography>
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          gap: 2,
          mb: 2,
        }}
      >
        <IconButton
          size="sm"
          variant="outlined"
          onClick={() => setCount((c) => c - 1)}
        >
          <Remove />
        </IconButton>
        <Typography fontWeight="md" textColor="text.secondary">
          {count}
        </Typography>
        <IconButton
          size="sm"
          variant="outlined"
          onClick={() => setCount((c) => c + 1)}
        >
          <Add />
        </IconButton>
      </Box>

      <CardContent orientation="horizontal">
        <div>
          <Typography level="body-xs">Total price:</Typography>
          <Typography fontSize="lg" fontWeight="lg">
            ${10000 * count}
          </Typography>
        </div>
        <Button
          variant="solid"
          type="submit"
          size="md"
          aria-label="Explore Bahamas Islands"
          sx={{ ml: "auto", alignSelf: "center" }}
          style={{
            backgroundColor: theme.btnColor,
            color: theme.textColor,
          }}
        >
          Reservation
        </Button>
      </CardContent>
    </Card>
  );
}
