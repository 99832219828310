import { Link, useLocation, useMatches, useNavigate } from "react-router-dom";
import styled from "styled-components";
import getPathHeader from "../../functions/getPathHeader";
import { Helmet } from "react-helmet";
import { Paragraph, Title } from "../../components/common/Text";
import * as React from "react";
const Container = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  background-size: cover;
  background-position: center;
  background-color: ${(props) => props.theme.textColor};
  height: 400px;
`;

const RowContainer = styled.div`
  width: 100%;
  height: fit-content;
  display: flex;
  justify-content: space-around;
  align-items: center;
  flex-direction: row;
  padding: 20px 0;
  gap: 10px;
`;

const ColumnContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
`;

const ReservationButton = styled.button`
  color: ${(props) => props.theme.textColor};
  background-color: ${(props) => props.theme.btnColor};
  font-family: "Cardo", serif;
  @media (max-width: 950px) {
    font-size: 10px;
  }
  font-size: 19px;
  font-weight: 100;
  width: 150px;
  height: 30px;
  @media (max-width: 950px) {
    width: 75px;
    height: 15px;
  }
  cursor: pointer;
  border: 0;
  border-radius: 5px;
  display: flex;
  justify-content: center;
  align-items: center;

  &:hover {
    background-color: ${(props) => props.theme.lightColor};
    color: ${(props) => props.theme.textColor};
    transition: 0.5s;
  }
`;

const HorzontalLine = styled.hr`
  background-color: ${(props) => props.theme.btnColor};
  border: none;
  width: 100px;
  height: 3px;
`;
// 다른 url로 링크를 연결해주는 컴포넌트
function Header() {
  const location = useLocation();
  const pathHeader = getPathHeader(location.pathname);

  return (
    <Container>
      <ColumnContainer style={{ margin: "80px 0 0 0" }}>
        <Helmet>
          <title>{pathHeader}</title>
        </Helmet>
        <Title style={{ color: "white" }}>
          <h1>{pathHeader}</h1>
        </Title>
        <HorzontalLine style={{ margin: "10px 0" }} />
        <RowContainer style={{ justifyContent: "center" }}>
          <a href="https://www.instagram.com/sparkbird.tour.korea?igsh=YmE3czM5OHgza2Q%3D&utm_source=qr">
            <img
              src="./store/insta_white.png"
              style={{ width: "25px", height: "25px" }}
            />
          </a>
          <img
            src="./store/yt_white.png"
            style={{ width: "25px", height: "25px" }}
          />
          <img
            src="./store/whats_white.png"
            style={{ width: "25px", height: "25px" }}
          />
        </RowContainer>
        <Paragraph style={{ color: "white", maxWidth: "1200px" }}>
          We are a tour company specialized in Birding in South Korea
        </Paragraph>
      </ColumnContainer>
    </Container>
  );
}
export default Header;
