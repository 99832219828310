import React from "react";
import Carousel from "react-material-ui-carousel";
import { Paper } from "@mui/material";

import Box from "@mui/material/Box";
interface ICarousel {
  images: string[] | undefined;
}

function ImageCarousel({ images }: ICarousel) {
  return (
    <Box sx={{ width: 600, flexGrow: 1}}>
      <Carousel>
        {images?.map((image, i) => (
          <Paper
            sx={{
              margin: "10px",
              boxShadow: "0px 5px 10px 0px rgba(100, 100, 111, 0.2)",
              maxHeight: 600
            }}
          >
            <Box
              component="img"
              sx={{
                overflow: "hidden",
                objectFit: "contain", // cover
                width: "100%",
              }}
              src={image}
            ></Box>
          </Paper>
        ))}
      </Carousel>
    </Box>
  );
}

export default ImageCarousel;
