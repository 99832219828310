import React from "react";
import Box from "@mui/material/Box";
import Card from "@mui/material/Card";
import CardActions from "@mui/material/CardActions";
import CardContent from "@mui/material/CardContent";
import Grid from "@mui/material/Grid";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import Stack from "@mui/material/Stack";
import { theme } from "../../../theme-colors/theme";

interface PlacePostProps {
  place: string | undefined;
  season: string | undefined;
}

function BirdInfoCard({ place, season }: PlacePostProps) {
  const card = (
      <CardContent sx={{ backgroundColor: theme.blueColor }}>
        <Grid container spacing={0}>
          <Grid container item spacing={2}>
            <Grid item xs={4}>
              <Typography color={theme.textColor}>Location</Typography>
            </Grid>
            <Grid item xs={8}>
              <Typography color={theme.btnColor}>{}</Typography>
            </Grid>
          </Grid>
          <Grid container item spacing={1}>
            <Grid item xs={4}>
              <Typography color={theme.textColor}>Place</Typography>
            </Grid>
            <Grid item xs={8}>
              <Typography color={theme.btnColor}>{place}</Typography>
            </Grid>
          </Grid>
          <Grid container item spacing={1}>
            <Grid item xs={4}>
              <Typography color={theme.textColor}>Season</Typography>
            </Grid>
            <Grid item xs={8}>
              <Typography color={theme.btnColor}>{season}</Typography>
            </Grid>
          </Grid>
        </Grid>
      </CardContent>
  );

  // return (
  //   <Box sx={{ minWidth: 250 }}>
  //     <Card variant="outlined">{card}</Card>
  //   </Box>
  // );

  return (
    <Card sx={{ minWidth: 280 }}>
      <CardContent>
      <Typography sx={{ mb: 1.5 }} component="div">
        Place : {place}
        </Typography>
        
        <Typography sx={{ mb: 1.5 }} component="div">
          Season : {season}
        </Typography>
      </CardContent>
    </Card>
  );
}
export default BirdInfoCard;
