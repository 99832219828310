import styled from "styled-components";
import {
  ColumnContainer,
  ColumnStack,
  RowStack,
} from "../../components/common/Container";
import { Title } from "../../components/common/Text";

const Form = styled.form`
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
`;
interface IField {
  width: string;
  height: string;
}
const Field = styled.input<IField>`
  border-color: ${(props) => props.theme.bgColor};
  border-style: solid;
  border-width: 2px;
  margin: 10px 0px;
  width: ${(props) => props.width};
  height: ${(props) => props.height};

  @media (max-width: 950px) {
    font-size: 8px;
    width: ${(props) => Number.parseInt(props.width) / 1.5}px;
  }
`;
const SubmitButton = styled.button`
  font-family: "Cardo", serif;
  @media (max-width: 950px) {
    font-size: 8px;
    height: 20px;
    margin: 30px;
    width: ${500 / 1.5}px;
  }
  font-size: 15px;
  width: 500px;
  height: 40px;
  background-color: ${(props) => props.theme.btnColor};
  color: ${(props) => props.theme.textColor};
`;

function Reservation() {
  return (
    <ColumnContainer
      sx={{
        paddingBottom: "100px",
      }}
    >
      <ColumnStack>
        <Title>Let's book a birding tour!</Title>
        <Form>
          <RowStack flexDirection="row" justifyContent="space-between">
            <Field placeholder="Name" width="240px" height="30px"></Field>
            <Field placeholder="E-mail" width="240px" height="30px"></Field>
          </RowStack>
          <Field placeholder="Phone" width="500px" height="30px"></Field>
          <Field placeholder="Message" width="500px" height="250px"></Field>
        </Form>
        <SubmitButton>Submit</SubmitButton>
      </ColumnStack>
    </ColumnContainer>
  );
}
export default Reservation;
