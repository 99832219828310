export const EmailIcon = () => (
  <svg
    width="20"
    height="18"
    viewBox="0 0 35 35"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M5.83366 5.83301H29.167C30.7712 5.83301 32.0837 7.14551 32.0837 8.74967V26.2497C32.0837 27.8538 30.7712 29.1663 29.167 29.1663H5.83366C4.22949 29.1663 2.91699 27.8538 2.91699 26.2497V8.74967C2.91699 7.14551 4.22949 5.83301 5.83366 5.83301Z"
      stroke="#FFCA42"
      stroke-width="2"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M32.0837 8.75L17.5003 18.9583L2.91699 8.75"
      stroke="#FFCA42"
      stroke-width="2"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>
);
