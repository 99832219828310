import { ColumnContainer, ColumnStack, RowStack } from "../../../components/common/Container";
import { Form } from "react-router-dom";
import BirdUploader from "./BirdUploader";
import { Title } from "../../../components/common/Text";
import BirdList from "./BirdList";
import { useEffect, useState } from "react";
import { useQuery } from "@tanstack/react-query";
import { IBird, getAllBirds } from "../../../api/exploreApi";

import Tab from '@mui/material/Tab';
import TabContext from '@mui/lab/TabContext';
import TabList from '@mui/lab/TabList';
import TabPanel from '@mui/lab/TabPanel';
import { Box, Typography } from "@mui/material";
import PlaceUploader from "./PlaceUploader";
import BirdListForPlace from "./BirdListForPlace";
import BirdPlaceRegister from "./BirdPlaceRegister";

export default function Uploader() {
  const [value, setValue] = useState('1');

  const handleChange = (event: React.SyntheticEvent, newValue: string) => {
    setValue(newValue);
  };


  return (
    <ColumnContainer
      sx={{
        paddingBottom: "100px",
      }}
    >
      <TabContext value={value}>
        <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
          <ColumnStack>

            <TabList onChange={handleChange} aria-label="lab API tabs example">
              <Tab label="새 추가" value="1" />
              <Tab label="장소 추가" value="2" />
              <Tab label="장소-새 등록" value="3" />
            </TabList>
          </ColumnStack>

        </Box>
        <TabPanel value="1">
          <ColumnStack>
            <BirdUploader />
          </ColumnStack>
        </TabPanel>
        <TabPanel value="2">
          <ColumnStack>
            <PlaceUploader />
          </ColumnStack>
        </TabPanel>
        <TabPanel value="3">
          <ColumnStack>
            <BirdPlaceRegister />
          </ColumnStack>
        </TabPanel>
      </TabContext>

    </ColumnContainer>
  );
}