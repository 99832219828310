import React from "react";
import styled from "styled-components";
import PropTypes from "prop-types";
import mapData from "./mapData";
import { mapTheme } from "../../../../theme-colors/mapTheme";
import { mapAreaAtom } from "../atom";
import { useRecoilValue } from "recoil";
import { IArea } from "../../../../api/exploreApi";

const SvgMap = styled.svg`
  width: 100%;
  max-width: 500px;
  stroke: #777;
  stroke-width: 1;
  stroke-linecap: round;
  stroke-linejoin: round;
  :hover {
    outline: 0;
    fill: ${mapTheme.mapover};
  }
  :focus {
    outline: 0;
  }
  cursor: pointer;
`;

export const SouthKoreaSvgMap = ({ data, ...props }: SouthKoreaSvgMapProps) => {
  return (
    <SvgMap xmlns="http://www.w3.org/2000/svg" viewBox={mapData.viewBox}>
      {props.childrenBefore}

      {mapData.areas.map((area) => {
        const overArea = data.find((obj) => obj.name === area.eng_name);

        let count = overArea?.birdCount;
        if (!count) {
          count = 0;
        }
        let fillColor = mapTheme.mapbg;
        if (overArea?.selected) {
          fillColor = mapTheme.mapselect;
        }
        return (
          <path
            id={area.id}
            name={area.eng_name}
            d={area.path}
            onMouseOver={props.onAreaMouseOver}
            onMouseOut={props.onAreaMouseOut}
            onMouseMove={props.onAreaMouseMove}
            onClick={props.onAreaClick}
            onKeyDown={props.onAreaKeyDown}
            onFocus={props.onAreaFocus}
            onBlur={props.onAreaBlur}
            key={area.id}
            fill={fillColor}
          />
        );
      })}
      {props.childrenAfter}
    </SvgMap>
  );
};
type SouthKoreaSvgMapProps = {
  data: IArea[];
  childrenBefore?: any;
  childrenAfter?: any;
  onAreaMouseOver: (event: any) => void;
  onAreaMouseOut: (event: any) => void;
  onAreaMouseMove: (event: any) => void;
  onAreaClick: (event: any) => void;
  onAreaKeyDown?: (event: any) => void;
  onAreaFocus?: (event: any) => void;
  onAreaBlur?: (event: any) => void;
};
