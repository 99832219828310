import * as React from "react";
import AspectRatio from "@mui/joy/AspectRatio";
import Box from "@mui/joy/Box";
import Button from "@mui/joy/Button";
import Card from "@mui/joy/Card";
import CardContent from "@mui/joy/CardContent";
import Typography from "@mui/joy/Typography";
import Sheet from "@mui/joy/Sheet";
import { theme } from "../../../theme-colors/theme";
import { ColumnContainer } from "../../../components/common/Container";
import { useNavigate } from "react-router-dom";

function TourCard() {
  const navigate = useNavigate();

  return (
    <Box
      sx={{
        width: "80%",
        overflow: { xs: "auto", sm: "initial" },
      }}
    >
      <Card
        orientation="horizontal"
        sx={{
          width: "100%",
          flexWrap: "wrap",
          overflow: "auto",
          resize: "horizontal",
        }}
      >
        <AspectRatio flex ratio="1" maxHeight={250} sx={{ minWidth: 250 }}>
          <Box
            component="img"
            loading="eager"
            alt="no image"
            src="https://loremflickr.com/640/480/nature"
            sx={{
              display: "flex",
              objectFit: "cover",
              width: "100%",
              maxHeight: 350,
            }}
          />
        </AspectRatio>
        <CardContent>
          <Typography fontSize="xl" fontWeight="lg">
            Winter Tour - 10 days
          </Typography>
          <Typography level="body-sm" fontWeight="lg">
            Included : Guide, Room, Food & Snacks.
          </Typography>
          <Sheet
            sx={{
              borderRadius: "sm",
              p: 2,
              my: 2,
              display: "flex",
              gap: 2,
              "& > div": { flex: 1 },
            }}
          >
            <div>
              <Typography level="body-lg" fontWeight="lg">
                Date
              </Typography>
              <Typography fontWeight="sm">2025/11/01 - 2025/11/11</Typography>
            </div>

            <div>
              <Typography level="body-lg" fontWeight="lg"></Typography>
              <Typography fontWeight="sm"></Typography>
            </div>

            <div>
              <Typography level="body-lg" fontWeight="lg"></Typography>
              <Typography fontWeight="sm"></Typography>
            </div>
          </Sheet>
          <Typography fontWeight="lg" textAlign="end">
            Fee : $5000
          </Typography>
          <Box
            sx={{
              display: "flex",
              gap: 1.5,
              "& > button": { flex: 1 },
            }}
          >
            <Button
              style={{
                color: theme.textColor,
                backgroundColor: theme.btnColor,
              }}
              onClick={() => {
                navigate(`/tour/${1}`);
              }}
            >
              Reservation
            </Button>
          </Box>
        </CardContent>
      </Card>
    </Box>
  );
}
export default TourCard;
