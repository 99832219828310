// import GoogleMapReact from "google-map-react";
import { GoogleMap, useJsApiLoader, Marker } from "@react-google-maps/api";
import React from "react";
import { itinerary_data } from "../body_left/Itinerary";

export default function GoogleMapCard() {
  const containerStyle = {
    width: "100%",
    height: "600px",
  };

  const center = {
    lat: 37.89155578613281,
    lng: 126.74359893798828,
    zoom: 8,
  };

  // https://developers.google.com/maps/documentation/javascript/reference?csw=1&hl=ko#MapOptions
  const OPTIONS = {
    minZoom: 4,
    maxZoom: 18,
    fullscreenControl: false,
    streetViewControl: false,
  };

  const { isLoaded } = useJsApiLoader({
    id: "google-map-script",
    googleMapsApiKey: "AIzaSyCPjagqC1Fh-5625L4zjh4UiL0idwvySO8",
  });

  const [map, setMap] = React.useState(null);

  const onLoad = React.useCallback(function callback(map: any) {
    // const bounds = new window.google.maps.LatLngBounds(center);
    // map.fitBounds(bounds);
    map.setZoom(8);

    setMap(map);
  }, []);

  const onUnmount = React.useCallback(function callback(map: any) {
    setMap(null);
  }, []);

  return (
    <div
      style={{
        padding: "20px",
      }}
    >
      {isLoaded ? (
        <GoogleMap
          mapContainerStyle={containerStyle}
          center={center}
          zoom={10}
          onLoad={onLoad}
          onUnmount={onUnmount}
          options={OPTIONS}
        >
          {itinerary_data.map((place) => (
            <Marker
              position={place.geometry}
              label={place.id}
              title={place.location}
              onClick={() => {}}
            ></Marker>
          ))}
        </GoogleMap>
      ) : null}
    </div>
  );
}
