import styled from "styled-components";
import { ThreeDots } from "../../components/icons/ThreeDots";

const ColumnContainer = styled.div`
  /* min-width: fit-content; */
  /* width: 100%; */
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: ${(props) => props.theme.textColor};
  color: ${(props) => props.theme.bgColor};
  padding: 10px 20px;
  flex-grow: 1; /* 모든 자식 요소가 동일한 공간을 차지하도록 설정 */
`;
const RowContainer = styled.div`
  height: 100%;
  margin: 1% 10% 0 10%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: flex-start;
`;
const VerticalLine = styled.div`
  /* border-left: 1px solid ${(props) => props.theme.lightColor}; */
  /* height: 100px; */
`;
const Title = styled.div`
  font-family: "Cardo", serif;
  font-size: 23px;
  @media (max-width: 950px) {
    font-size: 13px;
  }
  padding-bottom: 20px;
`;
const Menu = styled.div`
  color: ${(props) => props.theme.lightColor};
  font-size: 18px;
  @media (max-width: 950px) {
    font-size: 9px;
  }
  font-weight: 100;
  padding-bottom: 7px;

  display: flex;
  align-items: center;
  div {
    padding-left: 7px;
  }
`;
const HorzontalLine = styled.hr`
  background-color: ${(props) => props.theme.lightColor};
  border: none;
  margin: 0 20%;
  width: 100%;
  height: 1px;
`;

const ContactInfo = styled.div`
  color: ${(props) => props.theme.lightColor};
  @media (max-width: 950px) {
    font-size: 8px;
  }
  font-size: 15px;
  font-weight: 100;
  padding: 5px;
  padding-left: 7px;
  word-wrap: break-word; /* 긴 단어를 줄 바꿈합니다. */
  overflow-wrap: break-word; /* 긴 단어를 줄 바꿈합니다. */
`;

function Footer() {
  return (
    <ColumnContainer>
      <RowContainer>
        <ColumnContainer style={{ alignItems: "flex-start" }}>
          <Title>Explore</Title>
          <Menu>
            <ThreeDots />
            <div>Main</div>
          </Menu>
          <Menu>
            <ThreeDots />
            <div>About</div>
          </Menu>
          <Menu>
            <ThreeDots />
            <div>Travel</div>
          </Menu>
          <Menu>
            <ThreeDots />
            <div>Gallery</div>
          </Menu>
          <Menu>
            <ThreeDots />
            <div>Contact</div>
          </Menu>
        </ColumnContainer>
        <VerticalLine />
        <ColumnContainer style={{ alignItems: "flex-start" }}>
          <Title>Contact</Title>
          <ContactInfo>Address : Gangnam-gu, Seoul, Korea.</ContactInfo>
          <ContactInfo>E-Mail : support@sparkbirdkorea.com</ContactInfo>
          <ContactInfo>Phone : </ContactInfo>
        </ColumnContainer>
      </RowContainer>
      <HorzontalLine />
      <ContactInfo>© SparkBirdKorea, Inc. All rights reserved.</ContactInfo>
    </ColumnContainer>
  );
}
export default Footer;
