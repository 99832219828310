import { readImageFromS3 } from "../../functions/readImageFromS3";

function About() {
  return (
    <>
      {/* - 여행사 소개 - 한국 탐조에 대한 전반적인 소개 자료 */}
      <ul style={{ height: "500px" }}></ul>
      <img alt="" src={readImageFromS3("logo/logobig_g.png")} width="500px"  />
    </>
  );
}

export default About;
