import * as React from 'react';
import Table from '@mui/joy/Table';
import { IBird, IPlace, deleteBird, deletePlace, getBirdById } from '../../../api/exploreApi';
import { DataGrid, GridColDef, GridRowParams, GridRowSelectionModel } from '@mui/x-data-grid';
import { TextField, Box, Container, Button } from '@mui/material';
import { useEffect, useState } from 'react';
import { Optional, useQuery } from '@tanstack/react-query';
import { useNavigate } from 'react-router-dom';

interface PlacesListProps {
  places: IPlace[] | undefined;
  handleRowClick?: (params: string) => void;
}
interface IDataGrid {
  id: number
  placeId: string
  name: string
}

export default function PlaceListForBird(props: PlacesListProps) {
  const columns: GridColDef<IDataGrid>[] = [
    { field: 'name', headerName: 'name', width: 300, },
    { field: 'placeId', headerName: 'placeId', width: 500 },
  ];


  const [searchText, setSearchText] = useState<string>("");
  const [pageSize, setPageSize] = useState<number>(5);
  const [selectedRows, setSelectedRows] = useState<GridRowSelectionModel>([]);
  const [rows, setRows] = useState<IDataGrid[]>([])


  const navigate = useNavigate();

  const handleSelectedRows = (newSelectionModel: GridRowSelectionModel) => {
    setSelectedRows(newSelectionModel);

    const selectedIds = newSelectionModel as number[];
    const placesToDetail = rows.filter(row => selectedIds.includes(row.id));
    if (props.handleRowClick) {
      props.handleRowClick(placesToDetail[0] ? placesToDetail[0].placeId : "");
    }
  }
  const handleSearch = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSearchText(event.target.value);
  };

  const handleDelete = async () => {
    if (selectedRows.length > 0) {
      if (window.confirm("선택한 항목을 삭제합니다")) {
        const selectedIds = selectedRows as number[];
        const placesToDelete = rows.filter(row => selectedIds.includes(row.id));

        for (const place of placesToDelete) {
          await deletePlace(place.placeId);
        }

        const newPlaces = rows.filter(row => !selectedIds.includes(row.id));
        setRows(newPlaces);
        setSelectedRows([]);
      } else {
      }
    }
  };

  const handleDetail = async () => {
    if (selectedRows.length > 0) {
      const selectedIds = selectedRows as number[];
      const placesToDetail = rows.filter(row => selectedIds.includes(row.id));
      navigate(`/explore/places/${placesToDetail[0].placeId}`, { state: { key: placesToDetail[0].placeId } });
    }
  };

  useEffect(() => {
    const filteredRows = props.places?.filter((place) =>
      place.name.toLowerCase().includes(searchText.toLowerCase())
    ).map((place, index) => ({
      id: index + 1, // or another unique identifier
      placeId: place.placeId,
      name: place.name,
    })) || [];

    setRows(filteredRows);
  }, [searchText, props.places]);


  return (
    <Container>
      <Box marginBottom={2}>
        <TextField
          label="Search"
          variant="outlined"
          fullWidth
          onChange={handleSearch}
          value={searchText}
        />
      </Box>
      <Box sx={{ height: 400, width: '100%' }} marginBottom={2}>
        <DataGrid
          rows={rows}
          columns={columns}
          initialState={{
            pagination: {
              paginationModel: {
                pageSize: 5,
              },
            },
          }}
          pageSizeOptions={[5]}
          checkboxSelection
          disableRowSelectionOnClick
          onRowSelectionModelChange={handleSelectedRows}
          disableMultipleRowSelection
        />
      </Box>
      <Box marginBottom={2} columnGap={2}>
        <Button
          sx={{ marginRight: "5px" }}
          variant="contained"
          color="error"
          onClick={handleDelete}
          disabled={selectedRows.length === 0}
        >
          Delete
        </Button>
        <Button
          variant="contained"
          color="info"
          onClick={handleDetail}
          disabled={selectedRows.length !== 1}
        >
          Detail
        </Button>
      </Box>
    </Container>
  );
}

