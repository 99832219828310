const BASE_URL = "https://apis.sparkbirdkorea.com";
// const BASE_URL = "http://localhost:80";

// Bird
export function getAllBirds() {
  return fetch(`${BASE_URL}/birds`).then((response) => response.json());
}
export function getBirdById(id: string | undefined) {
  return fetch(`${BASE_URL}/birds/${id}`).then((response) => response.json());
}
export function getBirdsByPlaceId(placeIdList: string[] | undefined) {
  return fetch(`${BASE_URL}/birds/places`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify({ placeIdList }),
  }).then((response) => response.json());
}
export function createBird(bird: ICreateBird | undefined) {
  const formData = new FormData();
  if (bird) {
    const birdCreateDto = {
      "name": bird.name,
      "content": bird.content,
      "monthIdList": bird.monthIdList
    }
    const birdCreateDtoBlob = new Blob([JSON.stringify(birdCreateDto)], { type: 'application/json' });
    formData.append('birdCreateDto', birdCreateDtoBlob);

    bird.files.forEach((file, index) => {
      formData.append('files', file, file.name);
    });
  }
  return fetch(`${BASE_URL}/birds`, {
    method: "POST",
    body: formData,
  }).then((response) => response.json());
}
export function deleteBird(id: string | undefined) {
  return fetch(`${BASE_URL}/birds/${id}`, {
    method: "DELETE"
  });
}



// Place
export function getAllPlaces(): Promise<IPlace[]> {
  return fetch(`${BASE_URL}/places`).then((response) => response.json());
}
export function getPlaceById(id: string | undefined) {
  return fetch(`${BASE_URL}/places/${id}`).then((response) => response.json());
}
export function getPlacesByBirdId(id: string | undefined) {
  return fetch(`${BASE_URL}/places/bird/${id}`).then((response) =>
    response.json()
  );
}
export function registBird(placeId: string | undefined, birdId: string | undefined) {
  return fetch(`${BASE_URL}/places/regist`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify({ placeId, birdId }),
  }).then((response) => response.json());
}
export function getPlacesByAreaId(areaIdList: string[] | undefined) {
  return fetch(`${BASE_URL}/places/areas`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify({ areaIdList }),
  }).then((response) => response.json());
}
export function createPlace(place: ICreatePlace | undefined) {
  const formData = new FormData();
  if (place) {
    const placeCreateDto = {
      "areaId": place.areaId,
      "name": place.name,
      "lat": place.lat,
      "lng": place.lng,
      "tips": place.tips,
      "level": place.level,
      "around": place.around,
      "hours": place.hours,
      "visitTime": place.visitTime
    }
    const placeCreateDtoBlob = new Blob([JSON.stringify(placeCreateDto)], { type: 'application/json' });
    formData.append('placeCreateDto', placeCreateDtoBlob);
    if (place.file)
      formData.append('file', place.file, place.file.name);

  }
  return fetch(`${BASE_URL}/places`, {
    method: "POST",
    body: formData,
  }).then((response) => response.json());
}
export function deletePlace(id: string | undefined) {
  return fetch(`${BASE_URL}/places/${id}`, {
    method: "DELETE"
  });
}

// Month
export function getMonthsByBirdId(id: string | undefined) {
  return fetch(`${BASE_URL}/months/bird/${id}`).then((response) =>
    response.json()
  );
}
export function getAllMonths() {
  return fetch(`${BASE_URL}/months`).then((response) => response.json());
}

// Area
export function getArea(id: string | undefined) {
  return fetch(`${BASE_URL}/areas/${id}`).then((response) => response.json());
}
export function getAllAreas() {
  return fetch(`${BASE_URL}/areas`).then((response) => response.json());
}

// Trip

// Image
export function getImageById(id: string | undefined) {
  return fetch(`${BASE_URL}/image/${id}`).then((response) => response.json());
}

export interface IBird {
  birdId: string;
  name: string;
  content: string;
}

export interface ICreateBird {
  name: string;
  content: string;
  monthIdList: string[];
  files: File[];
}

export interface IPhoto {
  url: string;
  user: number;
  title: string;
  id: number;
  description: string;
}
export interface IPlace {
  placeId: string;
  areaId: string;
  name: string;
  imageId: string;
  numBirds: number;
  tips: string;
  level: number;
  around: string;
  hours: number;
  visitTime: string;
  lat: number;
  lng: number;
}

export interface ICreatePlace {
  name: string;
  areaId: string;
  lat: number;
  lng: number;
  tips: string;
  level: number;
  around: string;
  hours: number;
  visitTime: string;
  file: File | undefined;
}

export interface IMonth {
  monthId: string;
  name: string;
  content: string;
}

export interface IArea {
  areaId: string;
  name: string;
  content: string;
  birdCount: number;
  selected: boolean;
  category: string;
}
export interface IMonth {
  monthId: string;
  name: string;
  content: string;
  selected: boolean;
  category: string;
}
