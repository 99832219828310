export const AddressIcon = () => (
  <svg
    width="20"
    height="18"
    viewBox="0 0 35 36"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M4.375 13.6253L17.5 3.41699L30.625 13.6253V29.667C30.625 30.4405 30.3177 31.1824 29.7707 31.7294C29.2237 32.2764 28.4819 32.5837 27.7083 32.5837H7.29167C6.51812 32.5837 5.77625 32.2764 5.22927 31.7294C4.68229 31.1824 4.375 30.4405 4.375 29.667V13.6253Z"
      stroke="#FFCA42"
      stroke-width="2"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M13.125 32.5833V18H21.875V32.5833"
      stroke="#FFCA42"
      stroke-width="2"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>
);
